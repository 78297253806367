import { RegionCity } from '../types/common-types'
const RegionCityListKz: RegionCity[] = [
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Мәскеу',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Абрамцево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Алабино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Апрелевка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Архангелское',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ашитково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Байқоңыр',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Бақшево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Балашиха',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Барыбино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Беломут',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ақ бағаналар',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Бородино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Бронницы',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Быково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Valuevo',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Вербильки',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Верея',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Көрінетін',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Внуково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Пролетариаттың көшбасшысы',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Волоколамск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Вороново',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Воскресенск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Восточный',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Востряково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Высоковск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Голецыно',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Деденево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Дедовск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Джержинский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Дмитров',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Долгопрудный',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Домоддово',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Дорохово',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Дрезна',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Емендер',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Дубна',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Егорьевск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Железнодорожный',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Жилево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Жуковский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Загорск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Загорянский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Запрудная',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Зарайск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Звенигород',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Зеленоград',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ивановка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Икша',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ильинский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Истра',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Калининград',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Кашира',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Керва',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Климовск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Сына',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Клязма',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Кожино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Кокошкино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Коломна',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Колюбакино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Королев',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Косино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Котельники',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Красково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Красноармейск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Красногорск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Краснозаводск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Краснознаменск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Қызыл тоқымашы',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Крюково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Кубинка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Купавна',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Куровское',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Орман қалашығы',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ликино-Дулево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Лобня',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Лопатинский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Лосино-Петровский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Лотошино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Лукино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Луховицы',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Литкарино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Львов',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Любертси',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Малаховка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Михайловское',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Михнево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Можейск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Монино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Мұқаново',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Мытищы',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Нарофоминск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Нахабино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Некрасовка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Немчиновка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Новобретцевский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Новоподрезково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ногинск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Обухово',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Одинцево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Мойнақ',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Көлдер',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Октябрский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Опалиха',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Орехово-Зуево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Павлов посады',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Первомайский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Құмдар',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Пироговский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Подольск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Полушкино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Правдинский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Теміржол вокзалы',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Пролетариан',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Протвино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Пушкино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Пущино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Радовицкий',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Раменское',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ройтов',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Решетниково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Спрингс',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Рошал',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Рублево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Руза',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Салтыковка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Солтүстік',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Сергиев Посад',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Күміс тоғандар',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Серпухов',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Солнечногорск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Солнсево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Софрино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Старая Купавна',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Старбеево',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Ступино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Сходня',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Талдом',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Текстилщик',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Темпо',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Тишково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Томилино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Троицк',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Тұголесский бор',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Тучково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Уваровка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Нақты',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Успенское',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Фирсановка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Фосфорит',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Фрязино',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Франово',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Химки',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Хорлово',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Хутково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Черкизово',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Черноголовка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Черусти',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Чехов',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Шарапово',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Шатура',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Шатурторф',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Шаховская',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Шереметьевский',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Щелково',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Щербинка',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Электрогорск',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Электросталь',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Электроугли',
  },
  {
    region: 'Мәскеу және Мәскеу облысы',
    city: 'Яхрома',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Санкт-Петербург',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Александровская',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Боксытогорск',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Большая Ижора',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Будогош',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Көтерілу',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Волосово',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Волхов',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Всеволожск',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Выборг',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Вырица',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Высоцк',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Гатчина',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Дружная горка',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Дубровка',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Ефимовский',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Зеленогорск',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Ивангород',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Каменногорск',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Кикерино',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Кингисепп',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Кириши',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Кировск',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Кобринское',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Колпино',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Коммунар',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Кронштадт',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Түлкі мұрын',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Лодейное полюс',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Ломоносов',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Шалғындар',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Павловск',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Паргово',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Петродворец',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Пикалево',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Подпорожье',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Приозерск',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Пушкин',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Сестрорецк',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Slates',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Сосновый Бор',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Тихвин',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Тосно',
  },
  {
    region: 'Санкт-Петербург және облыс',
    city: 'Шлиссельбург',
  },
  {
    region: 'Дыгея',
    city: 'Адыгейск',
  },
  {
    region: 'Дыгея',
    city: 'Майкоп',
  },
  {
    region: 'Алтай аумағы',
    city: 'Ақташ',
  },
  {
    region: 'Алтай аумағы',
    city: 'Акучиха',
  },
  {
    region: 'Алтай аумағы',
    city: 'Алейск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Алтай',
  },
  {
    region: 'Алтай аумағы',
    city: 'Баево',
  },
  {
    region: 'Алтай аумағы',
    city: 'Барнауыл',
  },
  {
    region: 'Алтай аумағы',
    city: 'Белово',
  },
  {
    region: 'Алтай аумағы',
    city: 'Белокурихха',
  },
  {
    region: 'Алтай аумағы',
    city: 'Белоярск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Бийск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Благовещенка',
  },
  {
    region: 'Алтай аумағы',
    city: 'Боровлянка',
  },
  {
    region: 'Алтай аумағы',
    city: 'Бөрлі',
  },
  {
    region: 'Алтай аумағы',
    city: 'Бурсоль',
  },
  {
    region: 'Алтай аумағы',
    city: 'Қасқыр',
  },
  {
    region: 'Алтай аумағы',
    city: 'Горно-Алтайск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Шахтер',
  },
  {
    region: 'Алтай аумағы',
    city: 'Эльцовка',
  },
  {
    region: 'Алтай аумағы',
    city: 'Залесово',
  },
  {
    region: 'Алтай аумағы',
    city: 'Заринск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Қиып алу',
  },
  {
    region: 'Алтай аумағы',
    city: 'Змейногорск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Стоун-он-Об',
  },
  {
    region: 'Алтай аумағы',
    city: 'Кілттер',
  },
  {
    region: 'Алтай аумағы',
    city: 'Қош-Агач',
  },
  {
    region: 'Алтай аумағы',
    city: 'Красногорское',
  },
  {
    region: 'Алтай аумағы',
    city: 'Краснощеково',
  },
  {
    region: 'Алтай аумағы',
    city: 'Құлынды',
  },
  {
    region: 'Алтай аумағы',
    city: 'Қытманово',
  },
  {
    region: 'Алтай аумағы',
    city: 'Мамонтово',
  },
  {
    region: 'Алтай аумағы',
    city: 'Новичиха',
  },
  {
    region: 'Алтай аумағы',
    city: 'Новоалтайск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Онғодай',
  },
  {
    region: 'Алтай аумағы',
    city: 'Павловск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Петропавл',
  },
  {
    region: 'Алтай аумағы',
    city: 'Поспелиха',
  },
  {
    region: 'Алтай аумағы',
    city: 'Ребриха',
  },
  {
    region: 'Алтай аумағы',
    city: 'Родино',
  },
  {
    region: 'Алтай аумағы',
    city: 'Рубцовск',
  },
  {
    region: 'Алтай аумағы',
    city: 'Славгород',
  },
  {
    region: 'Алтай аумағы',
    city: 'Смоленское',
  },
  {
    region: 'Алтай аумағы',
    city: 'Солонешное',
  },
  {
    region: 'Алтай аумағы',
    city: 'Салтон',
  },
  {
    region: 'Алтай аумағы',
    city: 'Староаллейское',
  },
  {
    region: 'Алтай аумағы',
    city: 'Табын',
  },
  {
    region: 'Алтай аумағы',
    city: 'Тальменка',
  },
  {
    region: 'Алтай аумағы',
    city: 'Топчиха',
  },
  {
    region: 'Алтай аумағы',
    city: 'Троицкое',
  },
  {
    region: 'Алтай аумағы',
    city: 'Тұрочак',
  },
  {
    region: 'Алтай аумағы',
    city: 'Түменсево',
  },
  {
    region: 'Алтай аумағы',
    city: 'Угловское',
  },
  {
    region: 'Алтай аумағы',
    city: 'Уст-Калманка',
  },
  {
    region: 'Алтай аумағы',
    city: 'Уст-Кан',
  },
  {
    region: 'Алтай аумағы',
    city: 'Уст-Көкса',
  },
  {
    region: 'Алтай аумағы',
    city: 'Өскемен',
  },
  {
    region: 'Алтай аумағы',
    city: 'Уст-Чарышская Пристань',
  },
  {
    region: 'Алтай аумағы',
    city: 'Хабар',
  },
  {
    region: 'Алтай аумағы',
    city: 'Целинное',
  },
  {
    region: 'Алтай аумағы',
    city: 'Шарышское',
  },
  {
    region: 'Алтай аумағы',
    city: 'Шебалино',
  },
  {
    region: 'Алтай аумағы',
    city: 'Шелаболиха',
  },
  {
    region: 'Алтай аумағы',
    city: 'Шипуново',
  },
  {
    region: 'Амур облысы',
    city: 'Айгун',
  },
  {
    region: 'Амур облысы',
    city: 'Арқалы',
  },
  {
    region: 'Амур облысы',
    city: 'Белогорск',
  },
  {
    region: 'Амур облысы',
    city: 'Благовещенка (Амур облысы)',
  },
  {
    region: 'Амур облысы',
    city: 'Бурея',
  },
  {
    region: 'Амур облысы',
    city: 'Возжаевка',
  },
  {
    region: 'Амур облысы',
    city: 'Екатеринославка',
  },
  {
    region: 'Амур облысы',
    city: 'Ерофей Павлович',
  },
  {
    region: 'Амур облысы',
    city: 'Завитинск',
  },
  {
    region: 'Амур облысы',
    city: 'Зейя',
  },
  {
    region: 'Амур облысы',
    city: 'Златустовский',
  },
  {
    region: 'Амур облысы',
    city: 'Ивановка',
  },
  {
    region: 'Амур облысы',
    city: 'Кобольдо',
  },
  {
    region: 'Амур облысы',
    city: 'Магдагачи',
  },
  {
    region: 'Амур облысы',
    city: 'Новобурейский',
  },
  {
    region: 'Амур облысы',
    city: 'Поярково',
  },
  {
    region: 'Амур облысы',
    city: 'Райчихинск',
  },
  {
    region: 'Амур облысы',
    city: 'Ромный',
  },
  {
    region: 'Амур облысы',
    city: 'Свободный',
  },
  {
    region: 'Амур облысы',
    city: 'Серышево',
  },
  {
    region: 'Амур облысы',
    city: 'Сковородино',
  },
  {
    region: 'Амур облысы',
    city: 'Стойба',
  },
  {
    region: 'Амур облысы',
    city: 'Тамбовка',
  },
  {
    region: 'Амур облысы',
    city: 'Тұнды',
  },
  {
    region: 'Амур облысы',
    city: 'Шимановск',
  },
  {
    region: 'Амур облысы',
    city: 'Екимчан',
  },
  {
    region: 'Амур облысы',
    city: 'Ядрино',
  },
  {
    region: 'Архангельск облысы',
    city: 'Амдерма',
  },
  {
    region: 'Архангельск облысы',
    city: 'Архангельск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Березник',
  },
  {
    region: 'Архангельск облысы',
    city: 'Вельск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Верхняя тома',
  },
  {
    region: 'Архангельск облысы',
    city: 'Волошка',
  },
  {
    region: 'Архангельск облысы',
    city: 'Вычегодский',
  },
  {
    region: 'Архангельск облысы',
    city: 'Жемса',
  },
  {
    region: 'Архангельск облысы',
    city: 'Ілеза',
  },
  {
    region: 'Архангельск облысы',
    city: 'Ильинское-Подомское',
  },
  {
    region: 'Архангельск облысы',
    city: 'Қаргополь',
  },
  {
    region: 'Архангельск облысы',
    city: 'Қарпогор',
  },
  {
    region: 'Архангельск облысы',
    city: 'Кодино',
  },
  {
    region: 'Архангельск облысы',
    city: 'Коноша',
  },
  {
    region: 'Архангельск облысы',
    city: 'Дрифтвуд',
  },
  {
    region: 'Архангельск облысы',
    city: 'Котлас',
  },
  {
    region: 'Архангельск облысы',
    city: 'Красноборск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Лешуконское',
  },
  {
    region: 'Архангельск облысы',
    city: 'Мезен',
  },
  {
    region: 'Архангельск облысы',
    city: 'Бейбіт',
  },
  {
    region: 'Архангельск облысы',
    city: 'Нарын-Мар',
  },
  {
    region: 'Архангельск облысы',
    city: 'Новодвинск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Ньяндома',
  },
  {
    region: 'Архангельск облысы',
    city: 'Онега',
  },
  {
    region: 'Архангельск облысы',
    city: 'Пинега',
  },
  {
    region: 'Архангельск облысы',
    city: 'Плесетск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Северодвинск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Сольвычегодск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Хольмогория',
  },
  {
    region: 'Архангельск облысы',
    city: 'Шенкурск',
  },
  {
    region: 'Архангельск облысы',
    city: 'Яренск',
  },
  {
    region: 'Астрахань облысы',
    city: 'Астрахань',
  },
  {
    region: 'Астрахань облысы',
    city: 'Ахтубинск',
  },
  {
    region: 'Астрахань облысы',
    city: 'Верхния Баскунчак',
  },
  {
    region: 'Астрахань облысы',
    city: 'Володарский',
  },
  {
    region: 'Астрахань облысы',
    city: 'Энотаевка',
  },
  {
    region: 'Астрахань облысы',
    city: 'Жұмыртқа',
  },
  {
    region: 'Астрахань облысы',
    city: 'Қамызақ',
  },
  {
    region: 'Астрахань облысы',
    city: 'Капустин Яр',
  },
  {
    region: 'Астрахань облысы',
    city: 'Красный Яр',
  },
  {
    region: 'Астрахань облысы',
    city: 'Лиман',
  },
  {
    region: 'Астрахань облысы',
    city: 'Началово',
  },
  {
    region: 'Астрахань облысы',
    city: 'Харабали',
  },
  {
    region: 'Астрахань облысы',
    city: 'Хорни Яр',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Ақсаково',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Амзя',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Аскино',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Баймақ',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Бакалаврлар',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Белебей',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Белорет',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Бижбуляк',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Бір',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Благовещенка',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Большеюстикинское',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Бураево',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Верхнеаркеево',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Жоғарғы қиғи',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Верхнье Татышлы',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Жоғарғы Авзян',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Давлеканово',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Дуван',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Наурызұлы',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Ермекеево',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Ермолаево',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Зилер',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Зирган',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Иглино',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Инцер',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Исянгулово',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Есілбай',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Кананикольское',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Қандыағаш',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Қараидель',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Карайдельский',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Киргиз-Мияки',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Красноусолский',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Күмертау',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Кушнаренко',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Малаяз',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Мелеуз',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Мезягутово',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Мраково',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Нефтекамск',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Октябрский',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Раевский',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Салават',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Сібір',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Старобальтачево',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Старосубхангулов',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Стерлибашево',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Стерлитамак',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Тұймазы',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Уфа',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Учалы',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Федоровка',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Чекмагуш',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Чишмы',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Шаран',
  },
  {
    region: 'Башқұртстан (Башқұртстан)',
    city: 'Қанаул',
  },
  {
    region: 'Белгород облысы',
    city: 'Алексеевка',
  },
  {
    region: 'Белгород облысы',
    city: 'Белгород',
  },
  {
    region: 'Белгород облысы',
    city: 'Борисовка',
  },
  {
    region: 'Белгород облысы',
    city: 'Валуйки',
  },
  {
    region: 'Белгород облысы',
    city: 'Вейделевка',
  },
  {
    region: 'Белгород облысы',
    city: 'Волоконовка',
  },
  {
    region: 'Белгород облысы',
    city: 'Грэйворон',
  },
  {
    region: 'Белгород облысы',
    city: 'Губкин',
  },
  {
    region: 'Белгород облысы',
    city: 'Уиллоу',
  },
  {
    region: 'Белгород облысы',
    city: 'Қысқа',
  },
  {
    region: 'Белгород облысы',
    city: 'Красногвардейское',
  },
  {
    region: 'Белгород облысы',
    city: 'Новый Осколь',
  },
  {
    region: 'Белгород облысы',
    city: 'Ракитное',
  },
  {
    region: 'Белгород облысы',
    city: 'Ровенки',
  },
  {
    region: 'Белгород облысы',
    city: 'Старый Оскол',
  },
  {
    region: 'Белгород облысы',
    city: 'Құрылысшы',
  },
  {
    region: 'Белгород облысы',
    city: 'Чернянка',
  },
  {
    region: 'Белгород облысы',
    city: 'Шебекино',
  },
  {
    region: 'Брянск облысы',
    city: 'Альтухово',
  },
  {
    region: 'Брянск облысы',
    city: 'Ақ қайың',
  },
  {
    region: 'Брянск облысы',
    city: 'Ақ жағалаулар',
  },
  {
    region: 'Брянск облысы',
    city: 'Үлкен Полпино',
  },
  {
    region: 'Брянск облысы',
    city: 'Брянск',
  },
  {
    region: 'Брянск облысы',
    city: 'Бытош',
  },
  {
    region: 'Брянск облысы',
    city: 'Выгоничи',
  },
  {
    region: 'Брянск облысы',
    city: 'Вышков',
  },
  {
    region: 'Брянск облысы',
    city: 'Гордеевка',
  },
  {
    region: 'Брянск облысы',
    city: 'Дубровка',
  },
  {
    region: 'Брянск облысы',
    city: 'Дятково',
  },
  {
    region: 'Брянск облысы',
    city: 'Жирятино',
  },
  {
    region: 'Брянск облысы',
    city: 'Жуковка',
  },
  {
    region: 'Брянск облысы',
    city: 'Злинка',
  },
  {
    region: 'Брянск облысы',
    city: 'Ивот',
  },
  {
    region: 'Брянск облысы',
    city: 'Карачев',
  },
  {
    region: 'Брянск облысы',
    city: 'Cage',
  },
  {
    region: 'Брянск облысы',
    city: 'Климово',
  },
  {
    region: 'Брянск облысы',
    city: 'Клинцы',
  },
  {
    region: 'Брянск облысы',
    city: 'Кокаревка',
  },
  {
    region: 'Брянск облысы',
    city: 'Комаричи',
  },
  {
    region: 'Брянск облысы',
    city: 'Красная гора',
  },
  {
    region: 'Брянск облысы',
    city: 'Шынтақ',
  },
  {
    region: 'Брянск облысы',
    city: 'Мглин',
  },
  {
    region: 'Брянск облысы',
    city: 'Навля',
  },
  {
    region: 'Брянск облысы',
    city: 'Новозыбков',
  },
  {
    region: 'Брянск облысы',
    city: 'Погар',
  },
  {
    region: 'Брянск облысы',
    city: 'Почеп',
  },
  {
    region: 'Брянск облысы',
    city: 'Ржаница',
  },
  {
    region: 'Брянск облысы',
    city: 'Рогнедин',
  },
  {
    region: 'Брянск облысы',
    city: 'Севск',
  },
  {
    region: 'Брянск облысы',
    city: 'Стародуб',
  },
  {
    region: 'Брянск облысы',
    city: 'Суземка',
  },
  {
    region: 'Брянск облысы',
    city: 'Сураж',
  },
  {
    region: 'Брянск облысы',
    city: 'Трубчевск',
  },
  {
    region: 'Брянск облысы',
    city: 'Унеча',
  },
  {
    region: 'Бурятия',
    city: 'Бабушкин',
  },
  {
    region: 'Бурятия',
    city: 'Багдарин',
  },
  {
    region: 'Бурятия',
    city: 'Баргузин',
  },
  {
    region: 'Бурятия',
    city: 'Баянгол',
  },
  {
    region: 'Бурятия',
    city: 'Бичура',
  },
  {
    region: 'Бурятия',
    city: 'Выдрино',
  },
  {
    region: 'Бурятия',
    city: 'Қаз көлі',
  },
  {
    region: 'Бурятия',
    city: 'Гусиноозерск',
  },
  {
    region: 'Бурятия',
    city: 'Зайграево',
  },
  {
    region: 'Бурятия',
    city: 'Закаменск',
  },
  {
    region: 'Бурятия',
    city: 'Иволгинск',
  },
  {
    region: 'Бурятия',
    city: 'Илка',
  },
  {
    region: 'Бурятия',
    city: 'Қабанск',
  },
  {
    region: 'Бурятия',
    city: 'Каменск',
  },
  {
    region: 'Бурятия',
    city: 'Кижинга',
  },
  {
    region: 'Бурятия',
    city: 'Құрымқан',
  },
  {
    region: 'Бурятия',
    city: 'Кирен',
  },
  {
    region: 'Бурятия',
    city: 'Кяхта',
  },
  {
    region: 'Бурятия',
    city: 'Мондс',
  },
  {
    region: 'Бурятия',
    city: 'Мухоршибир',
  },
  {
    region: 'Бурятия',
    city: 'Нижнеангарск',
  },
  {
    region: 'Бурятия',
    city: 'Өрлік',
  },
  {
    region: 'Бурятия',
    city: 'Петропавловка',
  },
  {
    region: 'Бурятия',
    city: 'Романовка',
  },
  {
    region: 'Бурятия',
    city: 'Северобайкалск',
  },
  {
    region: 'Бурятия',
    city: 'Селенгинск',
  },
  {
    region: 'Бурятия',
    city: 'Сосново-Озерское',
  },
  {
    region: 'Бурятия',
    city: 'Таксимо',
  },
  {
    region: 'Бурятия',
    city: 'Тұрынтаево',
  },
  {
    region: 'Бурятия',
    city: 'Ұлан-Уде',
  },
  {
    region: 'Бурятия',
    city: 'Хоринск',
  },
  {
    region: 'Владимир облысы',
    city: 'Александров',
  },
  {
    region: 'Владимир облысы',
    city: 'Андреево',
  },
  {
    region: 'Владимир облысы',
    city: 'Анопино',
  },
  {
    region: 'Владимир облысы',
    city: 'Бавления',
  },
  {
    region: 'Владимир облысы',
    city: 'Балакирево',
  },
  {
    region: 'Владимир облысы',
    city: 'Боголюбово',
  },
  {
    region: 'Владимир облысы',
    city: 'Великодворский',
  },
  {
    region: 'Владимир облысы',
    city: 'Вербовский',
  },
  {
    region: 'Владимир облысы',
    city: 'Владимир',
  },
  {
    region: 'Владимир облысы',
    city: 'Вязники',
  },
  {
    region: 'Владимир облысы',
    city: 'Городища',
  },
  {
    region: 'Владимир облысы',
    city: 'Гороховец',
  },
  {
    region: 'Владимир облысы',
    city: 'Гусевский',
  },
  {
    region: 'Владимир облысы',
    city: 'Қаз кристалы',
  },
  {
    region: 'Владимир облысы',
    city: 'Золотково',
  },
  {
    region: 'Владимир облысы',
    city: 'Иванищы',
  },
  {
    region: 'Владимир облысы',
    city: 'Камешково',
  },
  {
    region: 'Владимир облысы',
    city: 'Қарабаново',
  },
  {
    region: 'Владимир облысы',
    city: 'Киржах',
  },
  {
    region: 'Владимир облысы',
    city: 'Ковров',
  },
  {
    region: 'Владимир облысы',
    city: 'Колчугино',
  },
  {
    region: 'Владимир облысы',
    city: 'Красная Горбатка',
  },
  {
    region: 'Владимир облысы',
    city: 'Меленки',
  },
  {
    region: 'Владимир облысы',
    city: 'Муром',
  },
  {
    region: 'Владимир облысы',
    city: 'Кокерельс',
  },
  {
    region: 'Владимир облысы',
    city: 'Покров',
  },
  {
    region: 'Владимир облысы',
    city: 'Собинка',
  },
  {
    region: 'Владимир облысы',
    city: 'Судогда',
  },
  {
    region: 'Владимир облысы',
    city: 'Суздаль',
  },
  {
    region: 'Владимир облысы',
    city: 'Юрьев-Польский',
  },
  {
    region: 'Волгоград облысы',
    city: 'Алексеевская',
  },
  {
    region: 'Волгоград облысы',
    city: 'Алущевск',
  },
  {
    region: 'Волгоград облысы',
    city: 'Быково',
  },
  {
    region: 'Волгоград облысы',
    city: 'Волгоград',
  },
  {
    region: 'Волгоград облысы',
    city: 'Волжский',
  },
  {
    region: 'Волгоград облысы',
    city: 'Елді мекен',
  },
  {
    region: 'Волгоград облысы',
    city: 'Дубовка',
  },
  {
    region: 'Волгоград облысы',
    city: 'Елан',
  },
  {
    region: 'Волгоград облысы',
    city: 'Жирновск',
  },
  {
    region: 'Волгоград облысы',
    city: 'Иловля',
  },
  {
    region: 'Волгоград облысы',
    city: 'Калач-он-Дон',
  },
  {
    region: 'Волгоград облысы',
    city: 'Камышин',
  },
  {
    region: 'Волгоград облысы',
    city: 'Кириллов',
  },
  {
    region: 'Волгоград облысы',
    city: 'Клетский',
  },
  {
    region: 'Волгоград облысы',
    city: 'Котельниково',
  },
  {
    region: 'Волгоград облысы',
    city: 'Котово',
  },
  {
    region: 'Волгоград облысы',
    city: 'Құмылженская',
  },
  {
    region: 'Волгоград облысы',
    city: 'Ленинск',
  },
  {
    region: 'Волгоград облысы',
    city: 'Михайловка',
  },
  {
    region: 'Волгоград облысы',
    city: 'Нехаевский',
  },
  {
    region: 'Волгоград облысы',
    city: 'Николаевск',
  },
  {
    region: 'Волгоград облысы',
    city: 'Новоаннинский',
  },
  {
    region: 'Волгоград облысы',
    city: 'Новониколаевское',
  },
  {
    region: 'Волгоград облысы',
    city: 'Ольховка',
  },
  {
    region: 'Волгоград облысы',
    city: 'Палласовка',
  },
  {
    region: 'Волгоград облысы',
    city: 'Рудня',
  },
  {
    region: 'Волгоград облысы',
    city: 'Светлый яр',
  },
  {
    region: 'Волгоград облысы',
    city: 'Серафимович',
  },
  {
    region: 'Волгоград облысы',
    city: 'Орта Ахтуба',
  },
  {
    region: 'Волгоград облысы',
    city: 'Сталинград',
  },
  {
    region: 'Волгоград облысы',
    city: 'Старая Полтавка',
  },
  {
    region: 'Волгоград облысы',
    city: 'Суровикино',
  },
  {
    region: 'Волгоград облысы',
    city: 'Урюпинск',
  },
  {
    region: 'Волгоград облысы',
    city: 'Фролово',
  },
  {
    region: 'Волгоград облысы',
    city: 'Чернышковский',
  },
  {
    region: 'Вологда облысы',
    city: 'Бабаев',
  },
  {
    region: 'Вологда облысы',
    city: 'Белозерск',
  },
  {
    region: 'Вологда облысы',
    city: 'Великой Устюг',
  },
  {
    region: 'Вологда облысы',
    city: 'Верховое',
  },
  {
    region: 'Вологда облысы',
    city: 'Вожега',
  },
  {
    region: 'Вологда облысы',
    city: 'Вологда',
  },
  {
    region: 'Вологда облысы',
    city: 'Вохтога',
  },
  {
    region: 'Вологда облысы',
    city: 'Вытегра',
  },
  {
    region: 'Вологда облысы',
    city: 'Грязовец',
  },
  {
    region: 'Вологда облысы',
    city: 'Кадников',
  },
  {
    region: 'Вологда облысы',
    city: 'Кадуй',
  },
  {
    region: 'Вологда облысы',
    city: 'Кихменг қалашығы',
  },
  {
    region: 'Вологда облысы',
    city: 'Липин Бор',
  },
  {
    region: 'Вологда облысы',
    city: 'Никольск',
  },
  {
    region: 'Вологда облысы',
    city: 'Нюксеница',
  },
  {
    region: 'Вологда облысы',
    city: 'Сұңқар',
  },
  {
    region: 'Вологда облысы',
    city: 'Зямжа',
  },
  {
    region: 'Вологда облысы',
    city: 'Тарног қалашығы',
  },
  {
    region: 'Вологда облысы',
    city: 'Тотма',
  },
  {
    region: 'Вологда облысы',
    city: 'Үстіжна',
  },
  {
    region: 'Вологда облысы',
    city: 'Харовск',
  },
  {
    region: 'Вологда облысы',
    city: 'Чагода',
  },
  {
    region: 'Вологда облысы',
    city: 'Череповец',
  },
  {
    region: 'Вологда облысы',
    city: 'Шевсна',
  },
  {
    region: 'Вологда облысы',
    city: 'Шуйское',
  },
  {
    region: 'Воронеж облысы',
    city: 'Анна',
  },
  {
    region: 'Воронеж облысы',
    city: 'Бобров',
  },
  {
    region: 'Воронеж облысы',
    city: 'Богучар',
  },
  {
    region: 'Воронеж облысы',
    city: 'Борисоглебск',
  },
  {
    region: 'Воронеж облысы',
    city: 'Бутурлиновка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Жоғарғы мамон',
  },
  {
    region: 'Воронеж облысы',
    city: 'Верхняя Хава',
  },
  {
    region: 'Воронеж облысы',
    city: 'Воробьевка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Воронеж',
  },
  {
    region: 'Воронеж облысы',
    city: 'Грибановский',
  },
  {
    region: 'Воронеж облысы',
    city: 'Давыдовка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Елан-Коленовский',
  },
  {
    region: 'Воронеж облысы',
    city: 'Калач',
  },
  {
    region: 'Воронеж облысы',
    city: 'Кантемировка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Лиски',
  },
  {
    region: 'Воронеж облысы',
    city: 'Нижнедевицк',
  },
  {
    region: 'Воронеж облысы',
    city: 'Жаңа Усман',
  },
  {
    region: 'Воронеж облысы',
    city: 'Новохоперск',
  },
  {
    region: 'Воронеж облысы',
    city: 'Ольховатка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Острогожск',
  },
  {
    region: 'Воронеж облысы',
    city: 'Павловск',
  },
  {
    region: 'Воронеж облысы',
    city: 'Панино',
  },
  {
    region: 'Воронеж облысы',
    city: 'Петропавловка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Поворино',
  },
  {
    region: 'Воронеж облысы',
    city: 'Подгоринский',
  },
  {
    region: 'Воронеж облысы',
    city: 'Рамон',
  },
  {
    region: 'Воронеж облысы',
    city: 'Репьевка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Россош',
  },
  {
    region: 'Воронеж облысы',
    city: 'Семилиуки',
  },
  {
    region: 'Воронеж облысы',
    city: 'Таловая',
  },
  {
    region: 'Воронеж облысы',
    city: 'Терновка',
  },
  {
    region: 'Воронеж облысы',
    city: 'Хохольский',
  },
  {
    region: 'Воронеж облысы',
    city: 'Ертіл',
  },
  {
    region: 'Воронеж облысы',
    city: 'Нововоронеж',
  },
  {
    region: 'Дағыстан',
    city: 'Агвали',
  },
  {
    region: 'Дағыстан',
    city: 'Акуша',
  },
  {
    region: 'Дағыстан',
    city: 'Ахты',
  },
  {
    region: 'Дағыстан',
    city: 'Ащысу',
  },
  {
    region: 'Дағыстан',
    city: 'Бабайурт',
  },
  {
    region: 'Дағыстан',
    city: 'Бежта',
  },
  {
    region: 'Дағыстан',
    city: 'Ботлих',
  },
  {
    region: 'Дағыстан',
    city: 'Буйнакск',
  },
  {
    region: 'Дағыстан',
    city: 'Вачи',
  },
  {
    region: 'Дағыстан',
    city: 'Гергебиль',
  },
  {
    region: 'Дағыстан',
    city: 'Гуниб',
  },
  {
    region: 'Дағыстан',
    city: 'Дағыстан оттары',
  },
  {
    region: 'Дағыстан',
    city: 'Дербент',
  },
  {
    region: 'Дағыстан',
    city: 'Дылым',
  },
  {
    region: 'Дағыстан',
    city: 'Ершовка',
  },
  {
    region: 'Дағыстан',
    city: 'Избербаш',
  },
  {
    region: 'Дағыстан',
    city: 'Қарабұдахкент',
  },
  {
    region: 'Дағыстан',
    city: 'Карата',
  },
  {
    region: 'Дағыстан',
    city: 'Каспийск',
  },
  {
    region: 'Дағыстан',
    city: 'Қасымкент',
  },
  {
    region: 'Дағыстан',
    city: 'Қизылұрт',
  },
  {
    region: 'Дағыстан',
    city: 'Қизляр',
  },
  {
    region: 'Дағыстан',
    city: 'Кочубей',
  },
  {
    region: 'Дағыстан',
    city: 'Кумух',
  },
  {
    region: 'Дағыстан',
    city: 'Құрах',
  },
  {
    region: 'Дағыстан',
    city: 'Мағарамкент',
  },
  {
    region: 'Дағыстан',
    city: 'Мәжілiс',
  },
  {
    region: 'Дағыстан',
    city: 'Махачкала',
  },
  {
    region: 'Дағыстан',
    city: 'Мечелта',
  },
  {
    region: 'Дағыстан',
    city: 'Новолакское',
  },
  {
    region: 'Дағыстан',
    city: 'Рутул',
  },
  {
    region: 'Дағыстан',
    city: 'Советский',
  },
  {
    region: 'Дағыстан',
    city: 'Тарумовка',
  },
  {
    region: 'Дағыстан',
    city: 'Теректі-Мектеп',
  },
  {
    region: 'Дағыстан',
    city: 'Тлярата',
  },
  {
    region: 'Дағыстан',
    city: 'Тпиг',
  },
  {
    region: 'Дағыстан',
    city: 'Үрқара',
  },
  {
    region: 'Дағыстан',
    city: 'Хасавюрт',
  },
  {
    region: 'Дағыстан',
    city: 'Хив',
  },
  {
    region: 'Дағыстан',
    city: 'Ғұнзах',
  },
  {
    region: 'Дағыстан',
    city: 'Цуриб',
  },
  {
    region: 'Дағыстан',
    city: 'Южно-Сухокумск',
  },
  {
    region: 'Еврей аймағы',
    city: 'Биробиджан',
  },
  {
    region: 'Иваново облысы',
    city: 'Архиповка',
  },
  {
    region: 'Иваново облысы',
    city: 'Жоғарғы Ландех',
  },
  {
    region: 'Иваново облысы',
    city: 'Вичуга',
  },
  {
    region: 'Иваново облысы',
    city: 'Гаврилов Посад',
  },
  {
    region: 'Иваново облысы',
    city: 'Долматовский',
  },
  {
    region: 'Иваново облысы',
    city: 'Дуляпино',
  },
  {
    region: 'Иваново облысы',
    city: 'Заволжск',
  },
  {
    region: 'Иваново облысы',
    city: 'Заречный',
  },
  {
    region: 'Иваново облысы',
    city: 'Иваново',
  },
  {
    region: 'Иваново облысы',
    city: 'Иванковский',
  },
  {
    region: 'Иваново облысы',
    city: 'Ильинское-Хованское',
  },
  {
    region: 'Иваново облысы',
    city: 'Каминский',
  },
  {
    region: 'Иваново облысы',
    city: 'Кинешма',
  },
  {
    region: 'Иваново облысы',
    city: 'Комсомольск',
  },
  {
    region: 'Иваново облысы',
    city: 'Кохма',
  },
  {
    region: 'Иваново облысы',
    city: 'Лух',
  },
  {
    region: 'Иваново облысы',
    city: 'Палех',
  },
  {
    region: 'Иваново облысы',
    city: 'Пестяки',
  },
  {
    region: 'Иваново облысы',
    city: 'Привольжск',
  },
  {
    region: 'Иваново облысы',
    city: 'Пучеж',
  },
  {
    region: 'Иваново облысы',
    city: 'Спрингс',
  },
  {
    region: 'Иваново облысы',
    city: 'Савино',
  },
  {
    region: 'Иваново облысы',
    city: 'Соколское',
  },
  {
    region: 'Иваново облысы',
    city: 'Тейково',
  },
  {
    region: 'Иваново облысы',
    city: 'Фурманов',
  },
  {
    region: 'Иваново облысы',
    city: 'Шуя',
  },
  {
    region: 'Иваново облысы',
    city: 'Южа',
  },
  {
    region: 'Иваново облысы',
    city: 'Юрьевец',
  },
  {
    region: 'Иркутск облысы',
    city: 'Алексеевск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Әлзамай',
  },
  {
    region: 'Иркутск облысы',
    city: 'Алыгжер',
  },
  {
    region: 'Иркутск облысы',
    city: 'Ангарск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Артемовский',
  },
  {
    region: 'Иркутск облысы',
    city: 'Атағай',
  },
  {
    region: 'Иркутск облысы',
    city: 'Байкал',
  },
  {
    region: 'Иркутск облысы',
    city: 'Байкалск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Балаганск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Баяндай',
  },
  {
    region: 'Иркутск облысы',
    city: 'Бирюсинск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Бодайбо',
  },
  {
    region: 'Иркутск облысы',
    city: 'Большая Речка',
  },
  {
    region: 'Иркутск облысы',
    city: 'Үлкен шалғын',
  },
  {
    region: 'Иркутск облысы',
    city: 'Бохан',
  },
  {
    region: 'Иркутск облысы',
    city: 'Братск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Қараңыз',
  },
  {
    region: 'Иркутск облысы',
    city: 'Витимский',
  },
  {
    region: 'Иркутск облысы',
    city: 'Вихоревка',
  },
  {
    region: 'Иркутск облысы',
    city: 'Элантси',
  },
  {
    region: 'Иркутск облысы',
    city: 'Эрбогачен',
  },
  {
    region: 'Иркутск облысы',
    city: 'Железногорск-Ильимский',
  },
  {
    region: 'Иркутск облысы',
    city: 'Жигалово',
  },
  {
    region: 'Иркутск облысы',
    city: 'Ұпай',
  },
  {
    region: 'Иркутск облысы',
    city: 'Залари',
  },
  {
    region: 'Иркутск облысы',
    city: 'Жұлдыз',
  },
  {
    region: 'Иркутск облысы',
    city: 'Қыс',
  },
  {
    region: 'Иркутск облысы',
    city: 'Иркутск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Казачинское',
  },
  {
    region: 'Иркутск облысы',
    city: 'Качуг',
  },
  {
    region: 'Иркутск облысы',
    city: 'Квиток',
  },
  {
    region: 'Иркутск облысы',
    city: 'Киренск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Күйтун',
  },
  {
    region: 'Иркутск облысы',
    city: 'Күлтүк',
  },
  {
    region: 'Иркутск облысы',
    city: 'Құттық',
  },
  {
    region: 'Иркутск облысы',
    city: 'Момын',
  },
  {
    region: 'Иркутск облысы',
    city: 'Нижнеудинск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Васп',
  },
  {
    region: 'Иркутск облысы',
    city: 'Саянск',
  },
  {
    region: 'Иркутск облысы',
    city: 'Слиудянка',
  },
  {
    region: 'Иркутск облысы',
    city: 'Тайшет',
  },
  {
    region: 'Иркутск облысы',
    city: 'Тулун',
  },
  {
    region: 'Иркутск облысы',
    city: 'Өсоля-Сібір',
  },
  {
    region: 'Иркутск облысы',
    city: 'Өскемен',
  },
  {
    region: 'Иркутск облысы',
    city: 'Ус-Кут',
  },
  {
    region: 'Иркутск облысы',
    city: 'Усты-Ордынский',
  },
  {
    region: 'Иркутск облысы',
    city: 'Ус-Уда',
  },
  {
    region: 'Иркутск облысы',
    city: 'Черемхово',
  },
  {
    region: 'Иркутск облысы',
    city: 'Чунский',
  },
  {
    region: 'Иркутск облысы',
    city: 'Шелехов',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Бақсан',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Майский',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Нальчик',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Нартқала',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Салқын',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Советский',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Терек',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Тирняуз',
  },
  {
    region: 'Қабардин-Балқария',
    city: 'Чегем-Первое',
  },
  {
    region: 'Калининград облысы',
    city: 'Багратоновск',
  },
  {
    region: 'Калининград облысы',
    city: 'Балтиыск',
  },
  {
    region: 'Калининград облысы',
    city: 'Гвардейск',
  },
  {
    region: 'Калининград облысы',
    city: 'Гурьевск',
  },
  {
    region: 'Калининград облысы',
    city: 'Гусев',
  },
  {
    region: 'Калининград облысы',
    city: 'Железнодорожный',
  },
  {
    region: 'Калининград облысы',
    city: 'Зеленоградск',
  },
  {
    region: 'Калининград облысы',
    city: 'Знаменск',
  },
  {
    region: 'Калининград облысы',
    city: 'Кёнигсберг',
  },
  {
    region: 'Калининград облысы',
    city: 'Калининград',
  },
  {
    region: 'Калининград облысы',
    city: 'Кенисберг',
  },
  {
    region: 'Калининград облысы',
    city: 'Краснознаменск',
  },
  {
    region: 'Калининград облысы',
    city: 'Мамоново',
  },
  {
    region: 'Калининград облысы',
    city: 'Неман',
  },
  {
    region: 'Калининград облысы',
    city: 'Нестеров',
  },
  {
    region: 'Калининград облысы',
    city: 'Озерск',
  },
  {
    region: 'Калининград облысы',
    city: 'Полесск',
  },
  {
    region: 'Калининград облысы',
    city: 'Правдинск',
  },
  {
    region: 'Калининград облысы',
    city: 'Светлогорск',
  },
  {
    region: 'Калининград облысы',
    city: 'Жарық',
  },
  {
    region: 'Калининград облысы',
    city: 'Славянск',
  },
  {
    region: 'Калининград облысы',
    city: 'Совецк',
  },
  {
    region: 'Калининград облысы',
    city: 'Черняховский',
  },
  {
    region: 'Қалмақия',
    city: 'Аршан',
  },
  {
    region: 'Қалмақия',
    city: 'Каспий',
  },
  {
    region: 'Қалмақия',
    city: 'Комсомольский',
  },
  {
    region: 'Қалмақия',
    city: 'Кіші дербет',
  },
  {
    region: 'Қалмақия',
    city: 'Приютное',
  },
  {
    region: 'Қалмақия',
    city: 'Советский',
  },
  {
    region: 'Қалмақия',
    city: 'Троицкое',
  },
  {
    region: 'Қалмақия',
    city: 'Утта',
  },
  {
    region: 'Қалмақия',
    city: 'Цаган-Аман',
  },
  {
    region: 'Қалмақия',
    city: 'Елиста',
  },
  {
    region: 'Қалмақия',
    city: 'Юста',
  },
  {
    region: 'Қалмақия',
    city: 'Яшальта',
  },
  {
    region: 'Қалмақия',
    city: 'Яшкуль',
  },
  {
    region: 'Калуга облысы',
    city: 'Вавинино',
  },
  {
    region: 'Калуга облысы',
    city: 'Балабаново',
  },
  {
    region: 'Калуга облысы',
    city: 'Барятино',
  },
  {
    region: 'Калуга облысы',
    city: 'Белоусово',
  },
  {
    region: 'Калуга облысы',
    city: 'Бетлица',
  },
  {
    region: 'Калуга облысы',
    city: 'Боровск',
  },
  {
    region: 'Калуга облысы',
    city: 'Дугна',
  },
  {
    region: 'Калуга облысы',
    city: 'Дудоровский',
  },
  {
    region: 'Калуга облысы',
    city: 'Думиничи',
  },
  {
    region: 'Калуга облысы',
    city: 'Еленский',
  },
  {
    region: 'Калуга облысы',
    city: 'Жиздра',
  },
  {
    region: 'Калуга облысы',
    city: 'Тозу',
  },
  {
    region: 'Калуга облысы',
    city: 'Калуга',
  },
  {
    region: 'Калуга облысы',
    city: 'Киров',
  },
  {
    region: 'Калуга облысы',
    city: 'Козельск',
  },
  {
    region: 'Калуга облысы',
    city: 'Кондрово',
  },
  {
    region: 'Калуга облысы',
    city: 'Людиново',
  },
  {
    region: 'Калуга облысы',
    city: 'Малоярославец',
  },
  {
    region: 'Калуга облысы',
    city: 'Медын',
  },
  {
    region: 'Калуга облысы',
    city: 'Мещовск',
  },
  {
    region: 'Калуга облысы',
    city: 'Мосальск',
  },
  {
    region: 'Калуга облысы',
    city: 'Обнинск',
  },
  {
    region: 'Калуга облысы',
    city: 'Пржемысль',
  },
  {
    region: 'Калуга облысы',
    city: 'Спас-Деменск',
  },
  {
    region: 'Калуга облысы',
    city: 'Сухиничи',
  },
  {
    region: 'Калуга облысы',
    city: 'Таруса',
  },
  {
    region: 'Калуга облысы',
    city: 'Ульяново',
  },
  {
    region: 'Калуга облысы',
    city: 'Ферзиково',
  },
  {
    region: 'Калуга облысы',
    city: 'Хвастовичи',
  },
  {
    region: 'Калуга облысы',
    city: 'Жүхнов',
  },
  {
    region: 'Камчатка облысы',
    city: 'Атласово',
  },
  {
    region: 'Камчатка облысы',
    city: 'Аянка',
  },
  {
    region: 'Камчатка облысы',
    city: 'Большереск',
  },
  {
    region: 'Камчатка облысы',
    city: 'Вилючинск',
  },
  {
    region: 'Камчатка облысы',
    city: 'Желизово',
  },
  {
    region: 'Камчатка облысы',
    city: 'Ильпырский',
  },
  {
    region: 'Камчатка облысы',
    city: 'Каменское',
  },
  {
    region: 'Камчатка облысы',
    city: 'Кировский',
  },
  {
    region: 'Камчатка облысы',
    city: 'Кілттер',
  },
  {
    region: 'Камчатка облысы',
    city: 'Неттл',
  },
  {
    region: 'Камчатка облысы',
    city: 'Милково',
  },
  {
    region: 'Камчатка облысы',
    city: 'Никольское',
  },
  {
    region: 'Камчатка облысы',
    city: 'Озерновский',
  },
  {
    region: 'Камчатка облысы',
    city: 'Оссора',
  },
  {
    region: 'Камчатка облысы',
    city: 'Палана',
  },
  {
    region: 'Камчатка облысы',
    city: 'Қаған',
  },
  {
    region: 'Камчатка облысы',
    city: 'Пахачи',
  },
  {
    region: 'Камчатка облысы',
    city: 'Петропавл-Камчацкий',
  },
  {
    region: 'Камчатка облысы',
    city: 'Тигил',
  },
  {
    region: 'Камчатка облысы',
    city: 'Тиличики',
  },
  {
    region: 'Камчатка облысы',
    city: 'Өскемен',
  },
  {
    region: 'Камчатка облысы',
    city: 'Өскемен',
  },
  {
    region: 'Карелия',
    city: 'Қора',
  },
  {
    region: 'Карелия',
    city: 'Беломорск',
  },
  {
    region: 'Карелия',
    city: 'Валаам',
  },
  {
    region: 'Карелия',
    city: 'Вирандозеро',
  },
  {
    region: 'Карелия',
    city: 'Жирвас',
  },
  {
    region: 'Карелия',
    city: 'Деревянка',
  },
  {
    region: 'Карелия',
    city: 'Идель',
  },
  {
    region: 'Карелия',
    city: 'Ильинский',
  },
  {
    region: 'Карелия',
    city: 'Импалахти',
  },
  {
    region: 'Карелия',
    city: 'Калевала',
  },
  {
    region: 'Карелия',
    city: 'Кем',
  },
  {
    region: 'Карелия',
    city: 'Кестенга',
  },
  {
    region: 'Карелия',
    city: 'Кондопога',
  },
  {
    region: 'Карелия',
    city: 'Қостұмсықша',
  },
  {
    region: 'Карелия',
    city: 'Лахденпохджа',
  },
  {
    region: 'Карелия',
    city: 'Лухи',
  },
  {
    region: 'Карелия',
    city: 'Медвежьегорск',
  },
  {
    region: 'Карелия',
    city: 'Муезерский',
  },
  {
    region: 'Карелия',
    city: 'Олонец',
  },
  {
    region: 'Карелия',
    city: 'Петрозаводск',
  },
  {
    region: 'Карелия',
    city: 'Питкеранта',
  },
  {
    region: 'Карелия',
    city: 'Повенец',
  },
  {
    region: 'Карелия',
    city: 'Иірімжіп',
  },
  {
    region: 'Карелия',
    city: 'Пудож',
  },
  {
    region: 'Карелия',
    city: 'Сегежа',
  },
  {
    region: 'Карелия',
    city: 'Сортавала',
  },
  {
    region: 'Карелия',
    city: 'Софпорог',
  },
  {
    region: 'Карелия',
    city: 'Suojärvi',
  },
  {
    region: 'Кемерово облысы',
    city: 'Анжеро-Судженск',
  },
  {
    region: 'Кемерово облысы',
    city: 'Барзас',
  },
  {
    region: 'Кемерово облысы',
    city: 'Белово',
  },
  {
    region: 'Кемерово облысы',
    city: 'Белогорск',
  },
  {
    region: 'Кемерово облысы',
    city: 'Березовский',
  },
  {
    region: 'Кемерово облысы',
    city: 'Грамотено',
  },
  {
    region: 'Кемерово облысы',
    city: 'Гурьевск',
  },
  {
    region: 'Кемерово облысы',
    city: 'Ижморский',
  },
  {
    region: 'Кемерово облысы',
    city: 'Итатский',
  },
  {
    region: 'Кемерово облысы',
    city: 'Қалтан',
  },
  {
    region: 'Кемерово облысы',
    city: 'Кедровка',
  },
  {
    region: 'Кемерово облысы',
    city: 'Кемерово',
  },
  {
    region: 'Кемерово облысы',
    city: 'Киселевск',
  },
  {
    region: 'Кемерово облысы',
    city: 'Крапинский',
  },
  {
    region: 'Кемерово облысы',
    city: 'Ленинск-Кузнецкий',
  },
  {
    region: 'Кемерово облысы',
    city: 'Мариинск',
  },
  {
    region: 'Кемерово облысы',
    city: 'Междуреченск',
  },
  {
    region: 'Кемерово облысы',
    city: 'Мыски',
  },
  {
    region: 'Кемерово облысы',
    city: 'Новокузнецк',
  },
  {
    region: 'Кемерово облысы',
    city: 'Осинники',
  },
  {
    region: 'Кемерово облысы',
    city: 'Прокопьевск',
  },
  {
    region: 'Кемерово облысы',
    city: 'Индустриальная',
  },
  {
    region: 'Кемерово облысы',
    city: 'Тайга',
  },
  {
    region: 'Кемерово облысы',
    city: 'Таштагөл',
  },
  {
    region: 'Кемерово облысы',
    city: 'Тисуль',
  },
  {
    region: 'Кемерово облысы',
    city: 'Пештер',
  },
  {
    region: 'Кемерово облысы',
    city: 'Тяжинский',
  },
  {
    region: 'Кемерово облысы',
    city: 'Юрга',
  },
  {
    region: 'Кемерово облысы',
    city: 'Яшкино',
  },
  {
    region: 'Кемерово облысы',
    city: 'Ая',
  },
  {
    region: 'Киров облысы',
    city: 'Арбаж',
  },
  {
    region: 'Киров облысы',
    city: 'Аркөл',
  },
  {
    region: 'Киров облысы',
    city: 'Белая Хулуница',
  },
  {
    region: 'Киров облысы',
    city: 'Богородское',
  },
  {
    region: 'Киров облысы',
    city: 'Бурабай',
  },
  {
    region: 'Киров облысы',
    city: 'Верхошижемье',
  },
  {
    region: 'Киров облысы',
    city: 'Вятский поляна',
  },
  {
    region: 'Киров облысы',
    city: 'Зуевка',
  },
  {
    region: 'Киров облысы',
    city: 'Каринторф',
  },
  {
    region: 'Киров облысы',
    city: 'Кикнур',
  },
  {
    region: 'Киров облысы',
    city: 'Кильмес',
  },
  {
    region: 'Киров облысы',
    city: 'Киров',
  },
  {
    region: 'Киров облысы',
    city: 'Кирово-Чепецк',
  },
  {
    region: 'Киров облысы',
    city: 'Кирс',
  },
  {
    region: 'Киров облысы',
    city: 'Кобра',
  },
  {
    region: 'Киров облысы',
    city: 'Котельнич',
  },
  {
    region: 'Киров облысы',
    city: 'Құмения',
  },
  {
    region: 'Киров облысы',
    city: 'Ленинское',
  },
  {
    region: 'Киров облысы',
    city: 'Луза',
  },
  {
    region: 'Киров облысы',
    city: 'Малшы',
  },
  {
    region: 'Киров облысы',
    city: 'Мураши',
  },
  {
    region: 'Киров облысы',
    city: 'Нагорск',
  },
  {
    region: 'Киров облысы',
    city: 'Нема',
  },
  {
    region: 'Киров облысы',
    city: 'Нововятск',
  },
  {
    region: 'Киров облысы',
    city: 'Нолинск',
  },
  {
    region: 'Киров облысы',
    city: 'Омутнинск',
  },
  {
    region: 'Киров облысы',
    city: 'Опарино',
  },
  {
    region: 'Киров облысы',
    city: 'Оричи',
  },
  {
    region: 'Киров облысы',
    city: 'Пижанка',
  },
  {
    region: 'Киров облысы',
    city: 'Подосиновец',
  },
  {
    region: 'Киров облысы',
    city: 'Санчурск',
  },
  {
    region: 'Киров облысы',
    city: 'Шам',
  },
  {
    region: 'Киров облысы',
    city: 'Слободское',
  },
  {
    region: 'Киров облысы',
    city: 'Совецк',
  },
  {
    region: 'Киров облысы',
    city: 'Суна',
  },
  {
    region: 'Киров облысы',
    city: 'Тұжа',
  },
  {
    region: 'Киров облысы',
    city: 'Уни',
  },
  {
    region: 'Киров облысы',
    city: 'Үржум',
  },
  {
    region: 'Киров облысы',
    city: 'Фаленки',
  },
  {
    region: 'Киров облысы',
    city: 'Халтурин',
  },
  {
    region: 'Киров облысы',
    city: 'Юрья',
  },
  {
    region: 'Киров облысы',
    city: 'Яранск',
  },
  {
    region: 'Коми',
    city: 'Абез',
  },
  {
    region: 'Коми',
    city: 'Айкино',
  },
  {
    region: 'Коми',
    city: 'Верхняя Инта',
  },
  {
    region: 'Коми',
    city: 'Винга',
  },
  {
    region: 'Коми',
    city: 'Су',
  },
  {
    region: 'Коми',
    city: 'Басшы',
  },
  {
    region: 'Коми',
    city: 'Воркута',
  },
  {
    region: 'Коми',
    city: 'Вуктыл',
  },
  {
    region: 'Коми',
    city: 'Гешарт',
  },
  {
    region: 'Коми',
    city: 'Желецкий',
  },
  {
    region: 'Коми',
    city: 'Эмва',
  },
  {
    region: 'Коми',
    city: 'Заполярный',
  },
  {
    region: 'Коми',
    city: 'Іжма',
  },
  {
    region: 'Коми',
    city: 'Инта',
  },
  {
    region: 'Коми',
    city: 'Израиль',
  },
  {
    region: 'Коми',
    city: 'Қажером',
  },
  {
    region: 'Коми',
    city: 'Әрқайсы',
  },
  {
    region: 'Коми',
    city: 'Қожам',
  },
  {
    region: 'Коми',
    city: 'Қойгородок',
  },
  {
    region: 'Коми',
    city: 'Қорткерос',
  },
  {
    region: 'Коми',
    city: 'Кослан',
  },
  {
    region: 'Коми',
    city: 'Обячево',
  },
  {
    region: 'Коми',
    city: 'Печора',
  },
  {
    region: 'Коми',
    city: 'Сосногорск',
  },
  {
    region: 'Коми',
    city: 'Зыктывкар',
  },
  {
    region: 'Коми',
    city: 'Троицко-Печерск',
  },
  {
    region: 'Коми',
    city: 'Усинск',
  },
  {
    region: 'Коми',
    city: 'Үшқоңыр',
  },
  {
    region: 'Коми',
    city: 'Уст-Кулом',
  },
  {
    region: 'Коми',
    city: 'Өскемен',
  },
  {
    region: 'Коми',
    city: 'Ухта',
  },
  {
    region: 'Кострома облысы',
    city: 'Антропово',
  },
  {
    region: 'Кострома облысы',
    city: 'Боговарово',
  },
  {
    region: 'Кострома облысы',
    city: 'Буй',
  },
  {
    region: 'Кострома облысы',
    city: 'Волгореченск',
  },
  {
    region: 'Кострома облысы',
    city: 'Галич',
  },
  {
    region: 'Кострома облысы',
    city: 'Горчуха',
  },
  {
    region: 'Кострома облысы',
    city: 'Зебляки',
  },
  {
    region: 'Кострома облысы',
    city: 'Қады',
  },
  {
    region: 'Кострома облысы',
    city: 'Кельгрив',
  },
  {
    region: 'Кострома облысы',
    city: 'Кострома',
  },
  {
    region: 'Кострома облысы',
    city: 'Красный он-Волга',
  },
  {
    region: 'Кострома облысы',
    city: 'Макариев',
  },
  {
    region: 'Кострома облысы',
    city: 'Мантурово',
  },
  {
    region: 'Кострома облысы',
    city: 'Нерехта',
  },
  {
    region: 'Кострома облысы',
    city: 'Нейя',
  },
  {
    region: 'Кострома облысы',
    city: 'Островское',
  },
  {
    region: 'Кострома облысы',
    city: 'Павино',
  },
  {
    region: 'Кострома облысы',
    city: 'Парфентьево',
  },
  {
    region: 'Кострома облысы',
    city: 'Поназырево',
  },
  {
    region: 'Кострома облысы',
    city: 'Солигалич',
  },
  {
    region: 'Кострома облысы',
    city: 'Судиславль',
  },
  {
    region: 'Кострома облысы',
    city: 'Сусанино',
  },
  {
    region: 'Кострома облысы',
    city: 'Чухлома',
  },
  {
    region: 'Кострома облысы',
    city: 'Шаря',
  },
  {
    region: 'Кострома облысы',
    city: 'Шемятино',
  },
  {
    region: 'Краснодар облысы',
    city: 'Абинск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Абрау-Дюрсо',
  },
  {
    region: 'Краснодар облысы',
    city: 'Анапа',
  },
  {
    region: 'Краснодар облысы',
    city: 'Апшеронск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Армавир',
  },
  {
    region: 'Краснодар облысы',
    city: 'Архипо-Осиповка',
  },
  {
    region: 'Краснодар облысы',
    city: 'Афипский',
  },
  {
    region: 'Краснодар облысы',
    city: 'Ахтырский',
  },
  {
    region: 'Краснодар облысы',
    city: 'Ахуево',
  },
  {
    region: 'Краснодар облысы',
    city: 'Белореченск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Верхнебаканский',
  },
  {
    region: 'Краснодар облысы',
    city: 'Елді мекендер',
  },
  {
    region: 'Краснодар облысы',
    city: 'Желенджік',
  },
  {
    region: 'Краснодар облысы',
    city: 'Джагинская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Ыстық кілт',
  },
  {
    region: 'Краснодар облысы',
    city: 'Дзюбга',
  },
  {
    region: 'Краснодар облысы',
    city: 'Динская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Жейск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Ильский',
  },
  {
    region: 'Краснодар облысы',
    city: 'Қабардинка',
  },
  {
    region: 'Краснодар облысы',
    city: 'Калинино',
  },
  {
    region: 'Краснодар облысы',
    city: 'Калининская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Каменномостский',
  },
  {
    region: 'Краснодар облысы',
    city: 'Каневская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Кореновск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Красноармейская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Краснодар',
  },
  {
    region: 'Краснодар облысы',
    city: 'Кропоткин',
  },
  {
    region: 'Краснодар облысы',
    city: 'Крыловская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Крымск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Қорғанын',
  },
  {
    region: 'Краснодар облысы',
    city: 'Кущевская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Лабинск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Ленинградская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Майкоп',
  },
  {
    region: 'Краснодар облысы',
    city: 'Мостовское',
  },
  {
    region: 'Краснодар облысы',
    city: 'Новороссийск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Ризашылық',
  },
  {
    region: 'Краснодар облысы',
    city: 'Павловская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Приморско-Ахтарск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Северская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Славянск-он-Кубан',
  },
  {
    region: 'Краснодар облысы',
    city: 'Сочи',
  },
  {
    region: 'Краснодар облысы',
    city: 'Староминская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Старошербиновская',
  },
  {
    region: 'Краснодар облысы',
    city: 'Тбилиси',
  },
  {
    region: 'Краснодар облысы',
    city: 'Темрюк',
  },
  {
    region: 'Краснодар облысы',
    city: 'Тимашевск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Тихорецк',
  },
  {
    region: 'Краснодар облысы',
    city: 'Туапсе',
  },
  {
    region: 'Краснодар облысы',
    city: 'Тула',
  },
  {
    region: 'Краснодар облысы',
    city: 'Уст-Лабинск',
  },
  {
    region: 'Краснодар облысы',
    city: 'Шовгеновский',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Железногорск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Абаза',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Абақан',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Абан',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Агинское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Артемовск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Асқыз',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Ачинск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Байкит',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Балахта',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Балықсы',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Белый Яр',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Белтырский',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Бея',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Бискамжа',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Боготол',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Боград',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Богучаня',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Большая Мурта',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Үлкен Улуй',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Бородино',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Ванавара',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Верхнеймбатск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Горячегорск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Дзержинский',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Дивногорск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Диксон',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Дудинка',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Емеляново',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Енисейск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Ермаковское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Заозерный',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Зеленогорск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Игарка',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Идринское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Иланский',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Ирбейское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Казачинское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Канск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Қаратұз',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Гвардия',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Кежма',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Кодинск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Қозылқа',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Копьево',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Краснотуранск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Краснояр',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Курагино',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Лесосибирск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Минусинск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Мотыгино',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Назарово',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Нижний Ингах',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Новоселов',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Норильск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Партизанское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Пировское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Саяногорск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Северо-Енисейский',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Сосновоборск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Тасеево',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Таштып',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Турлар',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Туруханск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Түхтет',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Ужур',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Уст-Авам',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Ұяр',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Хатаньга',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Шериомушки',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Черногорск',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Шалинское',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Шарыпово',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Шира',
  },
  {
    region: 'Краснояр аумағы',
    city: 'Шушенское',
  },
  {
    region: 'Қорған облысы',
    city: 'Варгаши',
  },
  {
    region: 'Қорған облысы',
    city: 'Глядянское',
  },
  {
    region: 'Қорған облысы',
    city: 'Далматово',
  },
  {
    region: 'Қорған облысы',
    city: 'Қарғалы',
  },
  {
    region: 'Қорған облысы',
    city: 'Катайск',
  },
  {
    region: 'Қорған облысы',
    city: 'Кетово',
  },
  {
    region: 'Қорған облысы',
    city: 'Қорған',
  },
  {
    region: 'Қорған облысы',
    city: 'Құртамыш',
  },
  {
    region: 'Қорған облысы',
    city: 'Аққу',
  },
  {
    region: 'Қорған облысы',
    city: 'Макушино',
  },
  {
    region: 'Қорған облысы',
    city: 'Мишкино',
  },
  {
    region: 'Қорған облысы',
    city: 'Мокрусово',
  },
  {
    region: 'Қорған облысы',
    city: 'Петухово',
  },
  {
    region: 'Қорған облысы',
    city: 'Жарты',
  },
  {
    region: 'Қорған облысы',
    city: 'Сафакулево',
  },
  {
    region: 'Қорған облысы',
    city: 'Целинное',
  },
  {
    region: 'Қорған облысы',
    city: 'Шадринск',
  },
  {
    region: 'Қорған облысы',
    city: 'Шатрово',
  },
  {
    region: 'Қорған облысы',
    city: 'Дүрбелең',
  },
  {
    region: 'Қорған облысы',
    city: 'Шортан',
  },
  {
    region: 'Қорған облысы',
    city: 'Юргамыш',
  },
  {
    region: 'Курск облысы',
    city: 'Альменево',
  },
  {
    region: 'Курск облысы',
    city: 'Ақ',
  },
  {
    region: 'Курск облысы',
    city: 'Большой Солдатское',
  },
  {
    region: 'Курск облысы',
    city: 'Глушково',
  },
  {
    region: 'Курск облысы',
    city: 'Потяжка',
  },
  {
    region: 'Курск облысы',
    city: 'Дмитриев-Льговский',
  },
  {
    region: 'Курск облысы',
    city: 'Железногорск',
  },
  {
    region: 'Курск облысы',
    city: 'Золотухино',
  },
  {
    region: 'Курск облысы',
    city: 'Касторное',
  },
  {
    region: 'Курск облысы',
    city: 'Конышевка',
  },
  {
    region: 'Курск облысы',
    city: 'Коренево',
  },
  {
    region: 'Курск облысы',
    city: 'Курск',
  },
  {
    region: 'Курск облысы',
    city: 'Курчатов',
  },
  {
    region: 'Курск облысы',
    city: 'Щенский',
  },
  {
    region: 'Курск облысы',
    city: 'Лгов',
  },
  {
    region: 'Курск облысы',
    city: 'Мантурово',
  },
  {
    region: 'Курск облысы',
    city: 'Медвенка',
  },
  {
    region: 'Курск облысы',
    city: 'Обоян',
  },
  {
    region: 'Курск облысы',
    city: 'Понири',
  },
  {
    region: 'Курск облысы',
    city: 'Пристен',
  },
  {
    region: 'Курск облысы',
    city: 'Приямицыно',
  },
  {
    region: 'Курск облысы',
    city: 'Рыльск',
  },
  {
    region: 'Курск облысы',
    city: 'Сужа',
  },
  {
    region: 'Курск облысы',
    city: 'Тим',
  },
  {
    region: 'Курск облысы',
    city: 'Фатеж',
  },
  {
    region: 'Курск облысы',
    city: 'Хумутовка',
  },
  {
    region: 'Курск облысы',
    city: 'Черемисино',
  },
  {
    region: 'Курск облысы',
    city: 'Жигри',
  },
  {
    region: 'Липецк облысы',
    city: 'Кір',
  },
  {
    region: 'Липецк облысы',
    city: 'Данхов',
  },
  {
    region: 'Липецк облысы',
    city: 'Жақсы',
  },
  {
    region: 'Липецк облысы',
    city: 'Долгоруково',
  },
  {
    region: 'Липецк облысы',
    city: 'Елі',
  },
  {
    region: 'Липецк облысы',
    city: 'Задонск',
  },
  {
    region: 'Липецк облысы',
    city: 'Измалково',
  },
  {
    region: 'Липецк облысы',
    city: 'Казинка',
  },
  {
    region: 'Липецк облысы',
    city: 'Лебедян',
  },
  {
    region: 'Липецк облысы',
    city: 'Лев Толстой',
  },
  {
    region: 'Липецк облысы',
    city: 'Липецк',
  },
  {
    region: 'Липецк облысы',
    city: 'Тербуни',
  },
  {
    region: 'Липецк облысы',
    city: 'Усман',
  },
  {
    region: 'Липецк облысы',
    city: 'Хлевное',
  },
  {
    region: 'Липецк облысы',
    city: 'Чаплыгин',
  },
  {
    region: 'Магадан облысы',
    city: 'Анадыр',
  },
  {
    region: 'Магадан облысы',
    city: 'Атка',
  },
  {
    region: 'Магадан облысы',
    city: 'Балықтычан',
  },
  {
    region: 'Магадан облысы',
    city: 'Беринговский',
  },
  {
    region: 'Магадан облысы',
    city: 'Билибино',
  },
  {
    region: 'Магадан облысы',
    city: 'Большевик',
  },
  {
    region: 'Магадан облысы',
    city: 'Ванкарем',
  },
  {
    region: 'Магадан облысы',
    city: 'Юлитин',
  },
  {
    region: 'Магадан облысы',
    city: 'Кадикчан',
  },
  {
    region: 'Магадан облысы',
    city: 'Лоуренс',
  },
  {
    region: 'Магадан облысы',
    city: 'Магадан',
  },
  {
    region: 'Магадан облысы',
    city: 'Шмидт мүйіс',
  },
  {
    region: 'Магадан облысы',
    city: 'Ола',
  },
  {
    region: 'Магадан облысы',
    city: 'Омонск',
  },
  {
    region: 'Магадан облысы',
    city: 'Омбычан',
  },
  {
    region: 'Магадан облысы',
    city: 'Шатыр',
  },
  {
    region: 'Магадан облысы',
    city: 'Певек',
  },
  {
    region: 'Магадан облысы',
    city: 'Провиденс',
  },
  {
    region: 'Магадан облысы',
    city: 'Сеймчан',
  },
  {
    region: 'Магадан облысы',
    city: 'Синегорье',
  },
  {
    region: 'Магадан облысы',
    city: 'Сусуман',
  },
  {
    region: 'Магадан облысы',
    city: 'Устов-Белая',
  },
  {
    region: 'Магадан облысы',
    city: 'Уст-Омчуг',
  },
  {
    region: 'Магадан облысы',
    city: 'Жұптық',
  },
  {
    region: 'Магадан облысы',
    city: 'Эгвекинот',
  },
  {
    region: 'Магадан облысы',
    city: 'Берри',
  },
  {
    region: 'Мари Ел',
    city: 'Волжск',
  },
  {
    region: 'Мари Ел',
    city: 'Дубовский',
  },
  {
    region: 'Мари Ел',
    city: 'Звенигово',
  },
  {
    region: 'Мари Ел',
    city: 'Жошқар-Ола',
  },
  {
    region: 'Мари Ел',
    city: 'Килемарс',
  },
  {
    region: 'Мари Ел',
    city: 'Козмодемянск',
  },
  {
    region: 'Мари Ел',
    city: 'Куженер',
  },
  {
    region: 'Мари Ел',
    city: 'Мари-Төрек',
  },
  {
    region: 'Мари Ел',
    city: 'Медведево',
  },
  {
    region: 'Мари Ел',
    city: 'Морес',
  },
  {
    region: 'Мари Ел',
    city: 'Жаңа Ториал',
  },
  {
    region: 'Мари Ел',
    city: 'Оршанка',
  },
  {
    region: 'Мари Ел',
    city: 'Паранга',
  },
  {
    region: 'Мари Ел',
    city: 'Сернур',
  },
  {
    region: 'Мари Ел',
    city: 'Советский',
  },
  {
    region: 'Мари Ел',
    city: 'Юрино',
  },
  {
    region: 'Мордовия',
    city: 'Ардатов',
  },
  {
    region: 'Мордовия',
    city: 'Атурьево',
  },
  {
    region: 'Мордовия',
    city: 'Атяшево',
  },
  {
    region: 'Мордовия',
    city: 'Большой березники',
  },
  {
    region: 'Мордовия',
    city: 'Большой Игнатово',
  },
  {
    region: 'Мордовия',
    city: 'Жоғары',
  },
  {
    region: 'Мордовия',
    city: 'Шырша ағаштары',
  },
  {
    region: 'Мордовия',
    city: 'Зубова Поляна',
  },
  {
    region: 'Мордовия',
    city: 'Инсар',
  },
  {
    region: 'Мордовия',
    city: 'Кадошкино',
  },
  {
    region: 'Мордовия',
    city: 'Кемля',
  },
  {
    region: 'Мордовия',
    city: 'Ковылкино',
  },
  {
    region: 'Мордовия',
    city: 'Комсомольский',
  },
  {
    region: 'Мордовия',
    city: 'Кочкурово',
  },
  {
    region: 'Мордовия',
    city: 'Краснослободск',
  },
  {
    region: 'Мордовия',
    city: 'Лямбир',
  },
  {
    region: 'Мордовия',
    city: 'Ромоданово',
  },
  {
    region: 'Мордовия',
    city: 'Рузаевка',
  },
  {
    region: 'Мордовия',
    city: 'Саранск',
  },
  {
    region: 'Мордовия',
    city: 'Ескі Шайгово',
  },
  {
    region: 'Мордовия',
    city: 'Темников',
  },
  {
    region: 'Мордовия',
    city: 'Тенггушево',
  },
  {
    region: 'Мордовия',
    city: 'Торбеево',
  },
  {
    region: 'Мордовия',
    city: 'Шамзинка',
  },
  {
    region: 'Мурманск облысы',
    city: 'Апаттылық',
  },
  {
    region: 'Мурманск облысы',
    city: 'Африка',
  },
  {
    region: 'Мурманск облысы',
    city: 'Верхнетуломский',
  },
  {
    region: 'Мурманск облысы',
    city: 'Заозерск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Заполярный',
  },
  {
    region: 'Мурманск облысы',
    city: 'Зареченск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Мойын',
  },
  {
    region: 'Мурманск облысы',
    city: 'Зеленоборский',
  },
  {
    region: 'Мурманск облысы',
    city: 'Қандалақша',
  },
  {
    region: 'Мурманск облысы',
    city: 'Кильдинстрой',
  },
  {
    region: 'Мурманск облысы',
    city: 'Кировск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Ковдор',
  },
  {
    region: 'Мурманск облысы',
    city: 'Кола',
  },
  {
    region: 'Мурманск облысы',
    city: 'Конда',
  },
  {
    region: 'Мурманск облысы',
    city: 'Мончегорск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Мурманск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Мурмаши',
  },
  {
    region: 'Мурманск облысы',
    city: 'Никель',
  },
  {
    region: 'Мурманск облысы',
    city: 'Оленегорск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Полиарный зори',
  },
  {
    region: 'Мурманск облысы',
    city: 'Поляр',
  },
  {
    region: 'Мурманск облысы',
    city: 'Североморск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Снежногорск',
  },
  {
    region: 'Мурманск облысы',
    city: 'Умба',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Ардатов',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Арзамас',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Арья',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Балахна',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Богородск',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Большеченск',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Үлкен Болдино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Большой Козино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Большой Мурашкино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Үлкен Пичино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Бор',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Бутурлино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Уэйд',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Варнавино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Васильсурск',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Вактан',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Вача',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Велетма',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Ветлуга',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Виля',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Вознесенское',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Володарск',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Воротинец',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Ворсма',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Воскресенское',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Шығу',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Выкса',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Гаджино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Гидропеат',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Горбатов',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Горбатовка',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Городец',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Горький',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Дальня Константиново',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Дзержинск',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Дивеево',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Басқарма',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Заволжье',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Катунки',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Керженец',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Княгинино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Ковернино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Красный Баки',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Ктово',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Құлебақ',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Лукоянов',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Лысково',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Навашино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Нижний Новгород',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Павлово',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Первомайск',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Транспорт',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Пилна',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Жөндеу',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Саров',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Серғач',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Сеченово',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Сосновка',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Спасское',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Тенкино',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Тоншаево',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Уразовка',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Урен',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Чкаловск',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Шаранга',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Шайқы',
  },
  {
    region: 'Нижний Новгород (Горьковская)',
    city: 'Шахуня',
  },
  {
    region: 'Новгород облысы',
    city: 'Антсиферово',
  },
  {
    region: 'Новгород облысы',
    city: 'Батецкий',
  },
  {
    region: 'Новгород облысы',
    city: 'Үлкен Вишера',
  },
  {
    region: 'Новгород облысы',
    city: 'Боровичи',
  },
  {
    region: 'Новгород облысы',
    city: 'Валдай',
  },
  {
    region: 'Новгород облысы',
    city: 'Волот',
  },
  {
    region: 'Новгород облысы',
    city: 'Деманск',
  },
  {
    region: 'Новгород облысы',
    city: 'Зарубино',
  },
  {
    region: 'Новгород облысы',
    city: 'Крест',
  },
  {
    region: 'Новгород облысы',
    city: 'Ақтөбе',
  },
  {
    region: 'Новгород облысы',
    city: 'Малайя Вишера',
  },
  {
    region: 'Новгород облысы',
    city: 'Марево',
  },
  {
    region: 'Новгород облысы',
    city: 'Алаяқтық',
  },
  {
    region: 'Новгород облысы',
    city: 'Новгород',
  },
  {
    region: 'Новгород облысы',
    city: 'Окуловка',
  },
  {
    region: 'Новгород облысы',
    city: 'Парфино',
  },
  {
    region: 'Новгород облысы',
    city: 'Пестово',
  },
  {
    region: 'Новгород облысы',
    city: 'Поддорже',
  },
  {
    region: 'Новгород облысы',
    city: 'Солт',
  },
  {
    region: 'Новгород облысы',
    city: 'Старая Русса',
  },
  {
    region: 'Новгород облысы',
    city: 'Қылқан жапырақтылар',
  },
  {
    region: 'Новгород облысы',
    city: 'Хилл',
  },
  {
    region: 'Новгород облысы',
    city: 'Чудово',
  },
  {
    region: 'Новгород облысы',
    city: 'Шымск',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Баган',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Барабинск',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Бердск',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Биаса',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Болотное',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Венгерово',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Қанағаттандым',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Завялово',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Искитим',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Қарасуық',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Қарғат',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Колыван',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Краснозерское',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Крутиха',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Куйбышев',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Купино',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Кыштовка',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Маслянино',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Михайловский',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Мошково',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Новосибирск',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Ордынское',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Солтүстік',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Сюзунь',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Татарск',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Тогучин',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Убинское',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Уст-Тарка',
  },
  {
    region: 'Новосибирск облысы',
    city: 'ҚҚС',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Черепаново',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Чистоозерное',
  },
  {
    region: 'Новосибирск облысы',
    city: 'Чулым',
  },
  {
    region: 'Омбы облысы',
    city: 'Береговой',
  },
  {
    region: 'Омбы облысы',
    city: 'Большеечье',
  },
  {
    region: 'Омбы облысы',
    city: 'Үлкен Вуки',
  },
  {
    region: 'Омбы облысы',
    city: 'Горьковский',
  },
  {
    region: 'Омбы облысы',
    city: 'Знаменское',
  },
  {
    region: 'Омбы облысы',
    city: 'Есілкөл',
  },
  {
    region: 'Омбы облысы',
    city: 'Калачинск',
  },
  {
    region: 'Омбы облысы',
    city: 'Колосовка',
  },
  {
    region: 'Омбы облысы',
    city: 'Кормиловка',
  },
  {
    region: 'Омбы облысы',
    city: 'Крутинка',
  },
  {
    region: 'Омбы облысы',
    city: 'Любинский',
  },
  {
    region: 'Омбы облысы',
    city: 'Марьяновка',
  },
  {
    region: 'Омбы облысы',
    city: 'Муромцево',
  },
  {
    region: 'Омбы облысы',
    city: 'Назваевск',
  },
  {
    region: 'Омбы облысы',
    city: 'Нижняя Омка',
  },
  {
    region: 'Омбы облысы',
    city: 'Нововаршавка',
  },
  {
    region: 'Омбы облысы',
    city: 'Одесса',
  },
  {
    region: 'Омбы облысы',
    city: 'Оконешниково',
  },
  {
    region: 'Омбы облысы',
    city: 'Омбы',
  },
  {
    region: 'Омбы облысы',
    city: 'Павлодарка',
  },
  {
    region: 'Омбы облысы',
    city: 'Полтавка',
  },
  {
    region: 'Омбы облысы',
    city: 'Русская поляна',
  },
  {
    region: 'Омбы облысы',
    city: 'Саргацкой',
  },
  {
    region: 'Омбы облысы',
    city: 'Седельниково',
  },
  {
    region: 'Омбы облысы',
    city: 'Таурид',
  },
  {
    region: 'Омбы облысы',
    city: 'Тараз',
  },
  {
    region: 'Омбы облысы',
    city: 'Тебриз',
  },
  {
    region: 'Омбы облысы',
    city: 'Тюкалинск',
  },
  {
    region: 'Омбы облысы',
    city: 'Усть-Ишим',
  },
  {
    region: 'Омбы облысы',
    city: 'Черлак',
  },
  {
    region: 'Омбы облысы',
    city: 'Шербақұл',
  },
  {
    region: 'Орынбор облысы',
    city: 'Абдулино',
  },
  {
    region: 'Орынбор облысы',
    city: 'Адамовка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Айдырлы',
  },
  {
    region: 'Орынбор облысы',
    city: 'Ақбұлақ',
  },
  {
    region: 'Орынбор облысы',
    city: 'Ақкермановка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Әсекеево',
  },
  {
    region: 'Орынбор облысы',
    city: 'Беляевка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Бұғыруслан',
  },
  {
    region: 'Орынбор облысы',
    city: 'Бұзұлық',
  },
  {
    region: 'Орынбор облысы',
    city: 'Қаған',
  },
  {
    region: 'Орынбор облысы',
    city: 'Грачевка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Домбаровский',
  },
  {
    region: 'Орынбор облысы',
    city: 'Дубенский',
  },
  {
    region: 'Орынбор облысы',
    city: 'Елек',
  },
  {
    region: 'Орынбор облысы',
    city: 'Ириклинский',
  },
  {
    region: 'Орынбор облысы',
    city: 'Кувандык',
  },
  {
    region: 'Орынбор облысы',
    city: 'Құрманаевка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Матвеевка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Медногорск',
  },
  {
    region: 'Орынбор облысы',
    city: 'Новоорск',
  },
  {
    region: 'Орынбор облысы',
    city: 'Новосергиевка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Новотроицк',
  },
  {
    region: 'Орынбор облысы',
    city: 'Октябрское',
  },
  {
    region: 'Орынбор облысы',
    city: 'Орынбор',
  },
  {
    region: 'Орынбор облысы',
    city: 'Орск',
  },
  {
    region: 'Орынбор облысы',
    city: 'Первомайский',
  },
  {
    region: 'Орынбор облысы',
    city: 'Переволоцкий',
  },
  {
    region: 'Орынбор облысы',
    city: 'Пономаревка',
  },
  {
    region: 'Орынбор облысы',
    city: 'Саракташ',
  },
  {
    region: 'Орынбор облысы',
    city: 'Жарық',
  },
  {
    region: 'Орынбор облысы',
    city: 'Солтүстік',
  },
  {
    region: 'Орынбор облысы',
    city: 'Соль-Илецк',
  },
  {
    region: 'Орынбор облысы',
    city: 'Сорочинск',
  },
  {
    region: 'Орынбор облысы',
    city: 'Ташлы',
  },
  {
    region: 'Орынбор облысы',
    city: 'Тоцкой',
  },
  {
    region: 'Орынбор облысы',
    city: 'Түлген',
  },
  {
    region: 'Орынбор облысы',
    city: 'Шарлық',
  },
  {
    region: 'Орынбор облысы',
    city: 'Энерготик',
  },
  {
    region: 'Орынбор облысы',
    city: 'Түсінікті',
  },
  {
    region: 'Орыл өңірі',
    city: 'Болхов',
  },
  {
    region: 'Орыл өңірі',
    city: 'Верховье',
  },
  {
    region: 'Орыл өңірі',
    city: 'Глазуновка',
  },
  {
    region: 'Орыл өңірі',
    city: 'Дмитрий-Орловский',
  },
  {
    region: 'Орыл өңірі',
    city: 'Ұзын',
  },
  {
    region: 'Орыл өңірі',
    city: 'Залегош',
  },
  {
    region: 'Орыл өңірі',
    city: 'Змьевка',
  },
  {
    region: 'Орыл өңірі',
    city: 'Знаменское',
  },
  {
    region: 'Орыл өңірі',
    city: 'Көлпный',
  },
  {
    region: 'Орыл өңірі',
    city: 'Красная Заря',
  },
  {
    region: 'Орыл өңірі',
    city: 'Кромы',
  },
  {
    region: 'Орыл өңірі',
    city: 'Ливный',
  },
  {
    region: 'Орыл өңірі',
    city: 'Малоархангельск',
  },
  {
    region: 'Орыл өңірі',
    city: 'Мценск',
  },
  {
    region: 'Орыл өңірі',
    city: 'Нарышкино',
  },
  {
    region: 'Орыл өңірі',
    city: 'Новосиль',
  },
  {
    region: 'Орыл өңірі',
    city: 'Бүркіт',
  },
  {
    region: 'Орыл өңірі',
    city: 'Покровское',
  },
  {
    region: 'Орыл өңірі',
    city: 'Сосково',
  },
  {
    region: 'Орыл өңірі',
    city: 'Тросна',
  },
  {
    region: 'Орыл өңірі',
    city: 'Хомутово',
  },
  {
    region: 'Орыл өңірі',
    city: 'Хотинетс',
  },
  {
    region: 'Орыл өңірі',
    city: 'Шаблыкино',
  },
  {
    region: 'Пенза облысы',
    city: 'Башмаково',
  },
  {
    region: 'Пенза облысы',
    city: 'Беднодемьяновск',
  },
  {
    region: 'Пенза облысы',
    city: 'Беково',
  },
  {
    region: 'Пенза облысы',
    city: 'Белинский',
  },
  {
    region: 'Пенза облысы',
    city: 'Бессоновка',
  },
  {
    region: 'Пенза облысы',
    city: 'Вадинск',
  },
  {
    region: 'Пенза облысы',
    city: 'Верхозим',
  },
  {
    region: 'Пенза облысы',
    city: 'Елді мекен',
  },
  {
    region: 'Пенза облысы',
    city: 'Евлашево',
  },
  {
    region: 'Пенза облысы',
    city: 'Земетчино',
  },
  {
    region: 'Пенза облысы',
    city: 'Золотаревка',
  },
  {
    region: 'Пенза облысы',
    city: 'Иса',
  },
  {
    region: 'Пенза облысы',
    city: 'Каменка',
  },
  {
    region: 'Пенза облысы',
    city: 'Колышли',
  },
  {
    region: 'Пенза облысы',
    city: 'Кондолле',
  },
  {
    region: 'Пенза облысы',
    city: 'Кузнецк',
  },
  {
    region: 'Пенза облысы',
    city: 'Лопатино',
  },
  {
    region: 'Пенза облысы',
    city: 'Малай сердобасы',
  },
  {
    region: 'Пенза облысы',
    city: 'Мокшан',
  },
  {
    region: 'Пенза облысы',
    city: 'Наровчат',
  },
  {
    region: 'Пенза облысы',
    city: 'Неверкино',
  },
  {
    region: 'Пенза облысы',
    city: 'Нижний Ломов',
  },
  {
    region: 'Пенза облысы',
    city: 'Никольск',
  },
  {
    region: 'Пенза облысы',
    city: 'Пачелма',
  },
  {
    region: 'Пенза облысы',
    city: 'Пенза',
  },
  {
    region: 'Пенза облысы',
    city: 'Орыс Камешкир',
  },
  {
    region: 'Пенза облысы',
    city: 'Сердобск',
  },
  {
    region: 'Пенза облысы',
    city: 'Сосновоборск',
  },
  {
    region: 'Пенза облысы',
    city: 'Сура',
  },
  {
    region: 'Пенза облысы',
    city: 'Тамала',
  },
  {
    region: 'Пенза облысы',
    city: 'Шемышейка',
  },
  {
    region: 'Пермь облысы',
    city: 'Барда',
  },
  {
    region: 'Пермь облысы',
    city: 'Березники',
  },
  {
    region: 'Пермь облысы',
    city: 'Үлкен Соснова',
  },
  {
    region: 'Пермь облысы',
    city: 'Верещагино',
  },
  {
    region: 'Пермь облысы',
    city: 'Пайда',
  },
  {
    region: 'Пермь облысы',
    city: 'Горнозаводск',
  },
  {
    region: 'Пермь облысы',
    city: 'Гремячинск',
  },
  {
    region: 'Пермь облысы',
    city: 'Губаха',
  },
  {
    region: 'Пермь облысы',
    city: 'Добрянка',
  },
  {
    region: 'Пермь облысы',
    city: 'Элово',
  },
  {
    region: 'Пермь облысы',
    city: 'Зёкайка',
  },
  {
    region: 'Пермь облысы',
    city: 'Ильинский',
  },
  {
    region: 'Пермь облысы',
    city: 'Қарағай',
  },
  {
    region: 'Пермь облысы',
    city: 'Керчевский',
  },
  {
    region: 'Пермь облысы',
    city: 'Кизель',
  },
  {
    region: 'Пермь облысы',
    city: 'Скиф',
  },
  {
    region: 'Пермь облысы',
    city: 'Кочево',
  },
  {
    region: 'Пермь облысы',
    city: 'Красновишерск',
  },
  {
    region: 'Пермь облысы',
    city: 'Краснокамамск',
  },
  {
    region: 'Пермь облысы',
    city: 'Құдымқар',
  },
  {
    region: 'Пермь облысы',
    city: 'Куэда',
  },
  {
    region: 'Пермь облысы',
    city: 'Күнгір',
  },
  {
    region: 'Пермь облысы',
    city: 'Лысва',
  },
  {
    region: 'Пермь облысы',
    city: 'Нироб',
  },
  {
    region: 'Пермь облысы',
    city: 'Нитва',
  },
  {
    region: 'Пермь облысы',
    city: 'Октябрский',
  },
  {
    region: 'Пермь облысы',
    city: 'Орда',
  },
  {
    region: 'Пермь облысы',
    city: 'Васп',
  },
  {
    region: 'Пермь облысы',
    city: 'Оханск',
  },
  {
    region: 'Пермь облысы',
    city: 'Очер',
  },
  {
    region: 'Пермь облысы',
    city: 'Пермь',
  },
  {
    region: 'Пермь облысы',
    city: 'Соликамск',
  },
  {
    region: 'Пермь облысы',
    city: 'Суксун',
  },
  {
    region: 'Пермь облысы',
    city: 'Уинское',
  },
  {
    region: 'Пермь облысы',
    city: 'Усолит',
  },
  {
    region: 'Пермь облысы',
    city: 'Уст-Кишерт',
  },
  {
    region: 'Пермь облысы',
    city: 'Чайковский',
  },
  {
    region: 'Пермь облысы',
    city: 'Жиі',
  },
  {
    region: 'Пермь облысы',
    city: 'Чердын',
  },
  {
    region: 'Пермь облысы',
    city: 'Чернореченский',
  },
  {
    region: 'Пермь облысы',
    city: 'Чернушка',
  },
  {
    region: 'Пермь облысы',
    city: 'Чусовое',
  },
  {
    region: 'Пермь облысы',
    city: 'Ірлі қала',
  },
  {
    region: 'Пермь облысы',
    city: 'Юсва',
  },
  {
    region: 'Приморье крайы',
    city: 'Анучино',
  },
  {
    region: 'Приморье крайы',
    city: 'Арсеньев',
  },
  {
    region: 'Приморье крайы',
    city: 'Артем',
  },
  {
    region: 'Приморье крайы',
    city: 'Артемовский',
  },
  {
    region: 'Приморье крайы',
    city: 'Үлкен тас',
  },
  {
    region: 'Приморье крайы',
    city: 'Валентин',
  },
  {
    region: 'Приморье крайы',
    city: 'Владивосток',
  },
  {
    region: 'Приморье крайы',
    city: 'Высокогорск',
  },
  {
    region: 'Приморье крайы',
    city: 'Тау кілті',
  },
  {
    region: 'Приморье крайы',
    city: 'Тау',
  },
  {
    region: 'Приморье крайы',
    city: 'Дальнегорск',
  },
  {
    region: 'Приморье крайы',
    city: 'Дальнереченск',
  },
  {
    region: 'Приморье крайы',
    city: 'Зарубино',
  },
  {
    region: 'Приморье крайы',
    city: 'Кавалерово',
  },
  {
    region: 'Приморье крайы',
    city: 'Каменка',
  },
  {
    region: 'Приморье крайы',
    city: 'Тас балықшы',
  },
  {
    region: 'Приморье крайы',
    city: 'Кировский',
  },
  {
    region: 'Приморье крайы',
    city: 'Лазо',
  },
  {
    region: 'Приморье крайы',
    city: 'Лесозаводск',
  },
  {
    region: 'Приморье крайы',
    city: 'Лучегорск',
  },
  {
    region: 'Приморье крайы',
    city: 'Михайловка',
  },
  {
    region: 'Приморье крайы',
    city: 'Находка',
  },
  {
    region: 'Приморье крайы',
    city: 'Новопокровка',
  },
  {
    region: 'Приморье крайы',
    city: 'Ольга',
  },
  {
    region: 'Приморье крайы',
    city: 'Партизанск',
  },
  {
    region: 'Приморье крайы',
    city: 'Borderline',
  },
  {
    region: 'Приморье крайы',
    city: 'Покровка',
  },
  {
    region: 'Приморье крайы',
    city: 'орысша',
  },
  {
    region: 'Приморье крайы',
    city: 'Самарға',
  },
  {
    region: 'Приморье крайы',
    city: 'Славянка',
  },
  {
    region: 'Приморье крайы',
    city: 'Спасск-Дальный',
  },
  {
    region: 'Приморье крайы',
    city: 'Терней',
  },
  {
    region: 'Приморье крайы',
    city: 'Уссюрийск',
  },
  {
    region: 'Приморье крайы',
    city: 'Фокино',
  },
  {
    region: 'Приморье крайы',
    city: 'Хасан',
  },
  {
    region: 'Приморье крайы',
    city: 'Хороль',
  },
  {
    region: 'Приморье крайы',
    city: 'Черниговка',
  },
  {
    region: 'Приморье крайы',
    city: 'Чугевка',
  },
  {
    region: 'Приморье крайы',
    city: 'Яковлевка',
  },
  {
    region: 'Псков облысы',
    city: 'Бежаницы',
  },
  {
    region: 'Псков облысы',
    city: 'Великье Луки',
  },
  {
    region: 'Псков облысы',
    city: 'Гдов',
  },
  {
    region: 'Псков облысы',
    city: 'Дедовичи',
  },
  {
    region: 'Псков облысы',
    city: 'Төменгі',
  },
  {
    region: 'Псков облысы',
    city: 'Заплюсье',
  },
  {
    region: 'Псков облысы',
    city: 'Идритса',
  },
  {
    region: 'Псков облысы',
    city: 'Красногородское',
  },
  {
    region: 'Псков облысы',
    city: 'Кунья',
  },
  {
    region: 'Псков облысы',
    city: 'Локня',
  },
  {
    region: 'Псков облысы',
    city: 'Невель',
  },
  {
    region: 'Псков облысы',
    city: 'Новоржев',
  },
  {
    region: 'Псков облысы',
    city: 'Новосоколники',
  },
  {
    region: 'Псков облысы',
    city: 'Опочка',
  },
  {
    region: 'Псков облысы',
    city: 'Арал',
  },
  {
    region: 'Псков облысы',
    city: 'Палкино',
  },
  {
    region: 'Псков облысы',
    city: 'Печора',
  },
  {
    region: 'Псков облысы',
    city: 'Плюсса',
  },
  {
    region: 'Псков облысы',
    city: 'Порхов',
  },
  {
    region: 'Псков облысы',
    city: 'Псков',
  },
  {
    region: 'Псков облысы',
    city: 'Бос жерлер',
  },
  {
    region: 'Псков облысы',
    city: 'Пушкин қыраты',
  },
  {
    region: 'Псков облысы',
    city: 'Питалово',
  },
  {
    region: 'Псков облысы',
    city: 'Себеж',
  },
  {
    region: 'Псков облысы',
    city: 'Струга-Красный',
  },
  {
    region: 'Псков облысы',
    city: 'Усвятия',
  },
  {
    region: 'Ростов облысы',
    city: 'Азов',
  },
  {
    region: 'Ростов облысы',
    city: 'Ақсай',
  },
  {
    region: 'Ростов облысы',
    city: 'Алмаз',
  },
  {
    region: 'Ростов облысы',
    city: 'Аютинск',
  },
  {
    region: 'Ростов облысы',
    city: 'Багаевский',
  },
  {
    region: 'Ростов облысы',
    city: 'Батайск',
  },
  {
    region: 'Ростов облысы',
    city: 'Белая Қалытва',
  },
  {
    region: 'Ростов облысы',
    city: 'Боковская',
  },
  {
    region: 'Ростов облысы',
    city: 'Большая Мартыновка',
  },
  {
    region: 'Ростов облысы',
    city: 'Вешенская',
  },
  {
    region: 'Ростов облысы',
    city: 'Волгодонск',
  },
  {
    region: 'Ростов облысы',
    city: 'Күн шығысы',
  },
  {
    region: 'Ростов облысы',
    city: 'Алып',
  },
  {
    region: 'Ростов облысы',
    city: 'Шахтер',
  },
  {
    region: 'Ростов облысы',
    city: 'Гуково',
  },
  {
    region: 'Ростов облысы',
    city: 'Донецк',
  },
  {
    region: 'Ростов облысы',
    city: 'Донское',
  },
  {
    region: 'Ростов облысы',
    city: 'Дубовский',
  },
  {
    region: 'Ростов облысы',
    city: 'Егорлыкская',
  },
  {
    region: 'Ростов облысы',
    city: 'Жирнов',
  },
  {
    region: 'Ростов облысы',
    city: 'Қастерлі',
  },
  {
    region: 'Ростов облысы',
    city: 'Фабрика',
  },
  {
    region: 'Ростов облысы',
    city: 'Зверево',
  },
  {
    region: 'Ростов облысы',
    city: 'Зерноград',
  },
  {
    region: 'Ростов облысы',
    city: 'Зимовники',
  },
  {
    region: 'Ростов облысы',
    city: 'Кагальницкий',
  },
  {
    region: 'Ростов облысы',
    city: 'Қазан',
  },
  {
    region: 'Ростов облысы',
    city: 'Карьерлер',
  },
  {
    region: 'Ростов облысы',
    city: 'Каменск-Шахтинск',
  },
  {
    region: 'Ростов облысы',
    city: 'Қашар',
  },
  {
    region: 'Ростов облысы',
    city: 'Кокс',
  },
  {
    region: 'Ростов облысы',
    city: 'Константиновск',
  },
  {
    region: 'Ростов облысы',
    city: 'Красный Сулин',
  },
  {
    region: 'Ростов облысы',
    city: 'Куйбышево',
  },
  {
    region: 'Ростов облысы',
    city: 'Матвеев Қорған',
  },
  {
    region: 'Ростов облысы',
    city: 'Мигулинская',
  },
  {
    region: 'Ростов облысы',
    city: 'Миллерово',
  },
  {
    region: 'Ростов облысы',
    city: 'Милютинская',
  },
  {
    region: 'Ростов облысы',
    city: 'Морозовск',
  },
  {
    region: 'Ростов облысы',
    city: 'Новочеркасск',
  },
  {
    region: 'Ростов облысы',
    city: 'Новошахтинск',
  },
  {
    region: 'Ростов облысы',
    city: 'Обливская',
  },
  {
    region: 'Ростов облысы',
    city: 'Орловский',
  },
  {
    region: 'Ростов облысы',
    city: 'Песчанокопское',
  },
  {
    region: 'Ростов облысы',
    city: 'Покровское',
  },
  {
    region: 'Ростов облысы',
    city: 'Пролетарск',
  },
  {
    region: 'Ростов облысы',
    city: 'Жөндеу',
  },
  {
    region: 'Ростов облысы',
    city: 'Родионово-Несветайская',
  },
  {
    region: 'Ростов облысы',
    city: 'Ростов-на-Дону',
  },
  {
    region: 'Ростов облысы',
    city: 'Сальск',
  },
  {
    region: 'Ростов облысы',
    city: 'Семикаракорск',
  },
  {
    region: 'Ростов облысы',
    city: 'Жезқазған',
  },
  {
    region: 'Ростов облысы',
    city: 'Тарасовский',
  },
  {
    region: 'Ростов облысы',
    city: 'Тацинский',
  },
  {
    region: 'Ростов облысы',
    city: 'Уст-Донецк',
  },
  {
    region: 'Ростов облысы',
    city: 'Виргин жері',
  },
  {
    region: 'Ростов облысы',
    city: 'Цимлянск',
  },
  {
    region: 'Ростов облысы',
    city: 'Шалтыр',
  },
  {
    region: 'Ростов облысы',
    city: 'Чертково',
  },
  {
    region: 'Ростов облысы',
    city: 'Шахталар',
  },
  {
    region: 'Ростов облысы',
    city: 'Шолоховский',
  },
  {
    region: 'Рязань облысы',
    city: 'Александр Невский',
  },
  {
    region: 'Рязань облысы',
    city: 'Шахтер',
  },
  {
    region: 'Рязань облысы',
    city: 'Темір қаз',
  },
  {
    region: 'Рязань облысы',
    city: 'Елатма',
  },
  {
    region: 'Рязань облысы',
    city: 'Ерміш',
  },
  {
    region: 'Рязань облысы',
    city: 'Заречный',
  },
  {
    region: 'Рязань облысы',
    city: 'Захарово',
  },
  {
    region: 'Рязань облысы',
    city: 'Кадом',
  },
  {
    region: 'Рязань облысы',
    city: 'Қасымов',
  },
  {
    region: 'Рязань облысы',
    city: 'Кораблино',
  },
  {
    region: 'Рязань облысы',
    city: 'Милославское',
  },
  {
    region: 'Рязань облысы',
    city: 'Михайлов',
  },
  {
    region: 'Рязань облысы',
    city: 'Пителино',
  },
  {
    region: 'Рязань облысы',
    city: 'Пронск',
  },
  {
    region: 'Рязань облысы',
    city: 'Путятино',
  },
  {
    region: 'Рязань облысы',
    city: 'Балық',
  },
  {
    region: 'Рязань облысы',
    city: 'Ряжск',
  },
  {
    region: 'Рязань облысы',
    city: 'Рязань',
  },
  {
    region: 'Рязань облысы',
    city: 'Бәтеңке',
  },
  {
    region: 'Рязань облысы',
    city: 'Шедс',
  },
  {
    region: 'Рязань облысы',
    city: 'Сасово',
  },
  {
    region: 'Рязань облысы',
    city: 'Скопин',
  },
  {
    region: 'Рязань облысы',
    city: 'Спас-Клепики',
  },
  {
    region: 'Рязань облысы',
    city: 'Спасск-Рязанский',
  },
  {
    region: 'Рязань облысы',
    city: 'Старожилово',
  },
  {
    region: 'Рязань облысы',
    city: 'Ухолово',
  },
  {
    region: 'Рязань облысы',
    city: 'Чучково',
  },
  {
    region: 'Рязань облысы',
    city: 'Шатск',
  },
  {
    region: 'Рязань облысы',
    city: 'Шилово',
  },
  {
    region: 'Самар облысы',
    city: 'Алексеевка',
  },
  {
    region: 'Самар облысы',
    city: 'Безенчук',
  },
  {
    region: 'Самар облысы',
    city: 'Бай',
  },
  {
    region: 'Самар облысы',
    city: 'Богатыр',
  },
  {
    region: 'Самар облысы',
    city: 'Үлкен глушчиця',
  },
  {
    region: 'Самар облысы',
    city: 'Большая Черниговка',
  },
  {
    region: 'Самар облысы',
    city: 'Борское',
  },
  {
    region: 'Самар облысы',
    city: 'Волжский',
  },
  {
    region: 'Самар облысы',
    city: 'Жигулевск',
  },
  {
    region: 'Самар облысы',
    city: 'Зольное',
  },
  {
    region: 'Самар облысы',
    city: 'Исаклы',
  },
  {
    region: 'Самар облысы',
    city: 'Қамышлы',
  },
  {
    region: 'Самар облысы',
    city: 'Кинель',
  },
  {
    region: 'Самар облысы',
    city: 'Кинель-Черкассы',
  },
  {
    region: 'Самар облысы',
    city: 'Клявлино',
  },
  {
    region: 'Самар облысы',
    city: 'Мысықтар',
  },
  {
    region: 'Самар облысы',
    city: 'Красноармейское',
  },
  {
    region: 'Самар облысы',
    city: 'Красный Яр',
  },
  {
    region: 'Самар облысы',
    city: 'Куйбышев',
  },
  {
    region: 'Самар облысы',
    city: 'Нефтегорск',
  },
  {
    region: 'Самар облысы',
    city: 'Новокуйбышевск',
  },
  {
    region: 'Самар облысы',
    city: 'Октябрск',
  },
  {
    region: 'Самар облысы',
    city: 'Ризашылық',
  },
  {
    region: 'Самар облысы',
    city: 'Пестровка',
  },
  {
    region: 'Самар облысы',
    city: 'Похвистнево',
  },
  {
    region: 'Самар облысы',
    city: 'Волга өңірі',
  },
  {
    region: 'Самар облысы',
    city: 'Самара',
  },
  {
    region: 'Самар облысы',
    city: 'Сургут (Самара облысы)',
  },
  {
    region: 'Самар облысы',
    city: 'Сыздық',
  },
  {
    region: 'Самар облысы',
    city: 'Тоглиатти',
  },
  {
    region: 'Самар облысы',
    city: 'Хворостянка',
  },
  {
    region: 'Самар облысы',
    city: 'Чапаевск',
  },
  {
    region: 'Самар облысы',
    city: 'Челно-Вершинье',
  },
  {
    region: 'Самар облысы',
    city: 'Шеңгелді',
  },
  {
    region: 'Самар облысы',
    city: 'Шигонс',
  },
  {
    region: 'Саратов облысы',
    city: 'Александров қаған',
  },
  {
    region: 'Саратов облысы',
    city: 'Аркадак',
  },
  {
    region: 'Саратов облысы',
    city: 'Аткарск',
  },
  {
    region: 'Саратов облысы',
    city: 'Базар Қарабұлақ',
  },
  {
    region: 'Саратов облысы',
    city: 'Балаково',
  },
  {
    region: 'Саратов облысы',
    city: 'Балашов',
  },
  {
    region: 'Саратов облысы',
    city: 'Балтай',
  },
  {
    region: 'Саратов облысы',
    city: 'Қайта өрлеу',
  },
  {
    region: 'Саратов облысы',
    city: 'Волск',
  },
  {
    region: 'Саратов облысы',
    city: 'Воскресенское',
  },
  {
    region: 'Саратов облысы',
    city: 'Тау',
  },
  {
    region: 'Саратов облысы',
    city: 'Дергачи',
  },
  {
    region: 'Саратов облысы',
    city: 'Духовницкий',
  },
  {
    region: 'Саратов облысы',
    city: 'Екатериновка',
  },
  {
    region: 'Саратов облысы',
    city: 'Ершов',
  },
  {
    region: 'Саратов облысы',
    city: 'Заречный',
  },
  {
    region: 'Саратов облысы',
    city: 'Ивановка',
  },
  {
    region: 'Саратов облысы',
    city: 'Калининск',
  },
  {
    region: 'Саратов облысы',
    city: 'Каменский',
  },
  {
    region: 'Саратов облысы',
    city: 'Красноармейск',
  },
  {
    region: 'Саратов облысы',
    city: 'Красный кут',
  },
  {
    region: 'Саратов облысы',
    city: 'Балдыз таулары',
  },
  {
    region: 'Саратов облысы',
    city: 'Маркс',
  },
  {
    region: 'Саратов облысы',
    city: 'Мокрус',
  },
  {
    region: 'Саратов облысы',
    city: 'Новоузенск',
  },
  {
    region: 'Саратов облысы',
    city: 'Жаңа Бурабай',
  },
  {
    region: 'Саратов облысы',
    city: 'Озинки',
  },
  {
    region: 'Саратов облысы',
    city: 'Переляб',
  },
  {
    region: 'Саратов облысы',
    city: 'Петровск',
  },
  {
    region: 'Саратов облысы',
    city: 'Питерка',
  },
  {
    region: 'Саратов облысы',
    city: 'Пугачев',
  },
  {
    region: 'Саратов облысы',
    city: 'Тегіс',
  },
  {
    region: 'Саратов облысы',
    city: 'Романовка',
  },
  {
    region: 'Саратов облысы',
    city: 'Ртищево',
  },
  {
    region: 'Саратов облысы',
    city: 'Самойловка',
  },
  {
    region: 'Саратов облысы',
    city: 'Саратов',
  },
  {
    region: 'Саратов облысы',
    city: 'Степное',
  },
  {
    region: 'Саратов облысы',
    city: 'Татищево',
  },
  {
    region: 'Саратов облысы',
    city: 'Түріктер',
  },
  {
    region: 'Саратов облысы',
    city: 'Хвалинск',
  },
  {
    region: 'Саратов облысы',
    city: 'Энгельс',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Абылай',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Алдан',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Амга',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Батағай',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Бердігестях',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Беркакит',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Бестях',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Борогонцы',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Верхневилюйск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Верхнеколымск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Верхоянск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Вилюйск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Витим',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Власово',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Жиганск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Зырянка',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Қанғаласы',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Канкун',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Ленск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Майя',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Менкеря',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Бейбіт',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Нерёнгри',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Ничалах',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Нюрба',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Олекминск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Покровск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Сангар',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Сасылах',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Среднеколимск',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Сунтар',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Тикси',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Устов-Майя',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Уст-Нера',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Хандыға',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Хонуу',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Черский',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Шоқұрдах',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Чурапча',
  },
  {
    region: 'Саха (Якутия)',
    city: 'Якутск',
  },
  {
    region: 'Сахалин',
    city: 'Александровск-Сахалинский',
  },
  {
    region: 'Сахалин',
    city: 'Анбецу',
  },
  {
    region: 'Сахалин',
    city: 'Анива',
  },
  {
    region: 'Сахалин',
    city: 'Бошняково',
  },
  {
    region: 'Сахалин',
    city: 'Быков',
  },
  {
    region: 'Сахалин',
    city: 'Вахрушев',
  },
  {
    region: 'Сахалин',
    city: 'Сисайд',
  },
  {
    region: 'Сахалин',
    city: 'Гастелло',
  },
  {
    region: 'Сахалин',
    city: 'Горнозаводск',
  },
  {
    region: 'Сахалин',
    city: 'Долинск',
  },
  {
    region: 'Сахалин',
    city: 'Ильинский',
  },
  {
    region: 'Сахалин',
    city: 'Катангли',
  },
  {
    region: 'Сахалин',
    city: 'Корсаков',
  },
  {
    region: 'Сахалин',
    city: 'Курильск',
  },
  {
    region: 'Сахалин',
    city: 'Мақаров',
  },
  {
    region: 'Сахалин',
    city: 'Невельск',
  },
  {
    region: 'Сахалин',
    city: 'Ноглики',
  },
  {
    region: 'Сахалин',
    city: 'Оха',
  },
  {
    region: 'Сахалин',
    city: 'Поронайск',
  },
  {
    region: 'Сахалин',
    city: 'Северо-Курильск',
  },
  {
    region: 'Сахалин',
    city: 'Смирных',
  },
  {
    region: 'Сахалин',
    city: 'Томари',
  },
  {
    region: 'Сахалин',
    city: 'Тимовское',
  },
  {
    region: 'Сахалин',
    city: 'Углегорск',
  },
  {
    region: 'Сахалин',
    city: 'Хольмск',
  },
  {
    region: 'Сахалин',
    city: 'Шахтерск',
  },
  {
    region: 'Сахалин',
    city: 'Южно-Курильск',
  },
  {
    region: 'Сахалин',
    city: 'Южно-Сахалинск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Алапаевск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Алтынай',
  },
  {
    region: 'Свердлов облысы',
    city: 'Арамил',
  },
  {
    region: 'Свердлов облысы',
    city: 'Артемовский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Арти',
  },
  {
    region: 'Свердлов облысы',
    city: 'Асбест',
  },
  {
    region: 'Свердлов облысы',
    city: 'Ахит',
  },
  {
    region: 'Свердлов облысы',
    city: 'Байкалово',
  },
  {
    region: 'Свердлов облысы',
    city: 'Басяновский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Белоярский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Березовский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Богданович',
  },
  {
    region: 'Свердлов облысы',
    city: 'Буланаш',
  },
  {
    region: 'Свердлов облысы',
    city: 'Верхный Тагил',
  },
  {
    region: 'Свердлов облысы',
    city: 'Верхняя пышма',
  },
  {
    region: 'Свердлов облысы',
    city: 'Верхняя салда',
  },
  {
    region: 'Свердлов облысы',
    city: 'Верхняя Синячиха',
  },
  {
    region: 'Свердлов облысы',
    city: 'Верхняя тігісі',
  },
  {
    region: 'Свердлов облысы',
    city: 'Верхняя тура',
  },
  {
    region: 'Свердлов облысы',
    city: 'Верхотурье',
  },
  {
    region: 'Свердлов облысы',
    city: 'Аспалы',
  },
  {
    region: 'Свердлов облысы',
    city: 'Вовчанск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Воронцовка',
  },
  {
    region: 'Свердлов облысы',
    city: 'Гари',
  },
  {
    region: 'Свердлов облысы',
    city: 'Дегтярск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Екатеринбург',
  },
  {
    region: 'Свердлов облысы',
    city: 'Ертарский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Заводуспенское',
  },
  {
    region: 'Свердлов облысы',
    city: 'Зырян',
  },
  {
    region: 'Свердлов облысы',
    city: 'Зюзельский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Ивдель',
  },
  {
    region: 'Свердлов облысы',
    city: 'Изумруд',
  },
  {
    region: 'Свердлов облысы',
    city: 'Ирбит',
  },
  {
    region: 'Свердлов облысы',
    city: 'Is',
  },
  {
    region: 'Свердлов облысы',
    city: 'Каменск-Орал',
  },
  {
    region: 'Свердлов облысы',
    city: 'Камышлов',
  },
  {
    region: 'Свердлов облысы',
    city: 'Карпинск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Карпунинский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Качканар',
  },
  {
    region: 'Свердлов облысы',
    city: 'Кировград',
  },
  {
    region: 'Свердлов облысы',
    city: 'Краснотуринский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Красноуральск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Красноуфимск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Кушва',
  },
  {
    region: 'Свердлов облысы',
    city: 'Лесной',
  },
  {
    region: 'Свердлов облысы',
    city: 'Михайловск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Невянск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Нижний Серги',
  },
  {
    region: 'Свердлов облысы',
    city: 'Нижний Тагил',
  },
  {
    region: 'Свердлов облысы',
    city: 'Нижняя салда',
  },
  {
    region: 'Свердлов облысы',
    city: 'Нижняя тура',
  },
  {
    region: 'Свердлов облысы',
    city: 'Жаңа Ляля',
  },
  {
    region: 'Свердлов облысы',
    city: 'Новоуральск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Ус',
  },
  {
    region: 'Свердлов облысы',
    city: 'Первоуральск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Полевский',
  },
  {
    region: 'Свердлов облысы',
    city: 'Пышма',
  },
  {
    region: 'Свердлов облысы',
    city: 'Ревда',
  },
  {
    region: 'Свердлов облысы',
    city: 'Дир',
  },
  {
    region: 'Свердлов облысы',
    city: 'Свердлов',
  },
  {
    region: 'Свердлов облысы',
    city: 'Северуралск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Серов',
  },
  {
    region: 'Свердлов облысы',
    city: 'Сосва',
  },
  {
    region: 'Свердлов облысы',
    city: 'Средневральск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Сухой лог',
  },
  {
    region: 'Свердлов облысы',
    city: 'Сысерт',
  },
  {
    region: 'Свердлов облысы',
    city: 'Лагерьлер',
  },
  {
    region: 'Свердлов облысы',
    city: 'Тавда',
  },
  {
    region: 'Свердлов облысы',
    city: 'Талица',
  },
  {
    region: 'Свердлов облысы',
    city: 'Тұғұлым',
  },
  {
    region: 'Свердлов облысы',
    city: 'Туринск',
  },
  {
    region: 'Свердлов облысы',
    city: 'Турин Слобода',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Алағыр',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Ардон',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Беслан',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Бурон',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Владикавказ',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Дигора',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Моздок',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Орджоникидзе',
  },
  {
    region: 'Солтүстік Осетия',
    city: 'Чикола',
  },
  {
    region: 'Смоленск облысы',
    city: 'Велиж',
  },
  {
    region: 'Смоленск облысы',
    city: 'Верхнеднепровский',
  },
  {
    region: 'Смоленск облысы',
    city: 'Ворга',
  },
  {
    region: 'Смоленск облысы',
    city: 'Вязма',
  },
  {
    region: 'Смоленск облысы',
    city: 'Гагарин',
  },
  {
    region: 'Смоленск облысы',
    city: 'Глинка',
  },
  {
    region: 'Смоленск облысы',
    city: 'Голынки',
  },
  {
    region: 'Смоленск облысы',
    city: 'Демидов',
  },
  {
    region: 'Смоленск облысы',
    city: 'Десногорск',
  },
  {
    region: 'Смоленск облысы',
    city: 'Дорогобуж',
  },
  {
    region: 'Смоленск облысы',
    city: 'Духовщина',
  },
  {
    region: 'Смоленск облысы',
    city: 'Екимовичи',
  },
  {
    region: 'Смоленск облысы',
    city: 'Елня',
  },
  {
    region: 'Смоленск облысы',
    city: 'Ершічи',
  },
  {
    region: 'Смоленск облысы',
    city: 'Іздешково',
  },
  {
    region: 'Смоленск облысы',
    city: 'Қардымово',
  },
  {
    region: 'Смоленск облысы',
    city: 'Красный',
  },
  {
    region: 'Смоленск облысы',
    city: 'Монастырщина',
  },
  {
    region: 'Смоленск облысы',
    city: 'Новодугино',
  },
  {
    region: 'Смоленск облысы',
    city: 'Жөндеу',
  },
  {
    region: 'Смоленск облысы',
    city: 'Рославль',
  },
  {
    region: 'Смоленск облысы',
    city: 'Рудня',
  },
  {
    region: 'Смоленск облысы',
    city: 'Сафоново',
  },
  {
    region: 'Смоленск облысы',
    city: 'Смоленск',
  },
  {
    region: 'Смоленск облысы',
    city: 'Зычевка',
  },
  {
    region: 'Смоленск облысы',
    city: 'Угра',
  },
  {
    region: 'Смоленск облысы',
    city: 'Хиславичи',
  },
  {
    region: 'Смоленск облысы',
    city: 'Хольм-Жирковский',
  },
  {
    region: 'Смоленск облысы',
    city: 'Шумячи',
  },
  {
    region: 'Смоленск облысы',
    city: 'Жарцево',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Александровское',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Арзғыр',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Ризашылығымды білдіремін',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Будённовск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Георгиевск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Marvelous',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Домбай',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Донское',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Есентуки',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Железноводск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Зеленокумск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Мол',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Иноземцево',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Ипатово',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Қарачаевск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Кисловодск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Кочубеевское',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Красногвардейское',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Курсавка',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Левокумское',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Минеральная арақ',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Невинномыск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Нефтекумск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Новоалександровск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Новоалександровская',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Новопавловск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Новоселицкий',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Тосқауыл',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Пятигорск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Светлоград',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Солнечнодольск',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Ставрополь',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Степное',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Теберді',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Өскемен',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Хабез',
  },
  {
    region: 'Ставрополь аумағы',
    city: 'Черкесск',
  },
  {
    region: 'Тамбов облысы',
    city: 'Куперс',
  },
  {
    region: 'Тамбов облысы',
    city: 'Гавриловка Вторая',
  },
  {
    region: 'Тамбов облысы',
    city: 'Жердевка',
  },
  {
    region: 'Тамбов облысы',
    city: 'Знаменка',
  },
  {
    region: 'Тамбов облысы',
    city: 'Инжавино',
  },
  {
    region: 'Тамбов облысы',
    city: 'Кирсанов',
  },
  {
    region: 'Тамбов облысы',
    city: 'Котовск',
  },
  {
    region: 'Тамбов облысы',
    city: 'Мичуринск',
  },
  {
    region: 'Тамбов облысы',
    city: 'Мордово',
  },
  {
    region: 'Тамбов облысы',
    city: 'Моршанск',
  },
  {
    region: 'Тамбов облысы',
    city: 'Мучкапский',
  },
  {
    region: 'Тамбов облысы',
    city: 'Первомайский',
  },
  {
    region: 'Тамбов облысы',
    city: 'Петровское',
  },
  {
    region: 'Тамбов облысы',
    city: 'Пичаево',
  },
  {
    region: 'Тамбов облысы',
    city: 'Рассказово',
  },
  {
    region: 'Тамбов облысы',
    city: 'Ржакса',
  },
  {
    region: 'Тамбов облысы',
    city: 'Староюрьево',
  },
  {
    region: 'Тамбов облысы',
    city: 'Тамбов',
  },
  {
    region: 'Тамбов облысы',
    city: 'Тоқаревка',
  },
  {
    region: 'Тамбов облысы',
    city: 'Уварово',
  },
  {
    region: 'Тамбов облысы',
    city: 'Үмет',
  },
  {
    region: 'Татарстан',
    city: 'Агроз',
  },
  {
    region: 'Татарстан',
    city: 'Азнакаево',
  },
  {
    region: 'Татарстан',
    city: 'Ақсубаев',
  },
  {
    region: 'Татарстан',
    city: 'Ақтөбе',
  },
  {
    region: 'Татарстан',
    city: 'Алексеевское',
  },
  {
    region: 'Татарстан',
    city: 'Әлметьевск',
  },
  {
    region: 'Татарстан',
    city: 'Әлметьевск',
  },
  {
    region: 'Татарстан',
    city: 'Апастово',
  },
  {
    region: 'Татарстан',
    city: 'Арск',
  },
  {
    region: 'Татарстан',
    city: 'Баулы',
  },
  {
    region: 'Татарстан',
    city: 'Базар Матаки',
  },
  {
    region: 'Татарстан',
    city: 'Балтасы',
  },
  {
    region: 'Татарстан',
    city: 'Бай саба',
  },
  {
    region: 'Татарстан',
    city: 'Брежнев',
  },
  {
    region: 'Татарстан',
    city: 'Бұғылма',
  },
  {
    region: 'Татарстан',
    city: 'Буйнск',
  },
  {
    region: 'Татарстан',
    city: 'Васильево',
  },
  {
    region: 'Татарстан',
    city: 'Верхный Услон',
  },
  {
    region: 'Татарстан',
    city: 'Биік тау',
  },
  {
    region: 'Татарстан',
    city: 'Дербешкинский',
  },
  {
    region: 'Татарстан',
    city: 'Елабуга',
  },
  {
    region: 'Татарстан',
    city: 'Зайнск',
  },
  {
    region: 'Татарстан',
    city: 'Зеленодольск',
  },
  {
    region: 'Татарстан',
    city: 'Қазан',
  },
  {
    region: 'Татарстан',
    city: 'Қамское үстізі',
  },
  {
    region: 'Татарстан',
    city: 'Қарабаш',
  },
  {
    region: 'Татарстан',
    city: 'Куйбышев',
  },
  {
    region: 'Татарстан',
    city: 'Кукмод',
  },
  {
    region: 'Татарстан',
    city: 'Кукмор',
  },
  {
    region: 'Татарстан',
    city: 'Лайшево',
  },
  {
    region: 'Татарстан',
    city: 'Лениногорск',
  },
  {
    region: 'Татарстан',
    city: 'Мамадыш',
  },
  {
    region: 'Татарстан',
    city: 'Менделеевск',
  },
  {
    region: 'Татарстан',
    city: 'Мензелинск',
  },
  {
    region: 'Татарстан',
    city: 'Муслюмово',
  },
  {
    region: 'Татарстан',
    city: 'Набережный челный',
  },
  {
    region: 'Татарстан',
    city: 'Нижнекамск',
  },
  {
    region: 'Татарстан',
    city: 'Новошешминск',
  },
  {
    region: 'Татарстан',
    city: 'Нұрлат',
  },
  {
    region: 'Татарстан',
    city: 'Пестрецы',
  },
  {
    region: 'Татарстан',
    city: 'Рыбная слобода',
  },
  {
    region: 'Татарстан',
    city: 'Сарманово',
  },
  {
    region: 'Татарстан',
    city: 'Ескі ашытқы',
  },
  {
    region: 'Татарстан',
    city: 'Аунтис',
  },
  {
    region: 'Татарстан',
    city: 'Чистополь',
  },
  {
    region: 'Тверь облысы',
    city: 'Андреаполь',
  },
  {
    region: 'Тверь облысы',
    city: 'Бежетск',
  },
  {
    region: 'Тверь облысы',
    city: 'Ақ',
  },
  {
    region: 'Тверь облысы',
    city: 'Ақ қалашық',
  },
  {
    region: 'Тверь облысы',
    city: 'Березайка',
  },
  {
    region: 'Тверь облысы',
    city: 'Бологоэ',
  },
  {
    region: 'Тверь облысы',
    city: 'Васильевский мосс',
  },
  {
    region: 'Тверь облысы',
    city: 'Выползово',
  },
  {
    region: 'Тверь облысы',
    city: 'Вышный Волочек',
  },
  {
    region: 'Тверь облысы',
    city: 'Жарковский',
  },
  {
    region: 'Тверь облысы',
    city: 'Батыс Двина',
  },
  {
    region: 'Тверь облысы',
    city: 'Аудан',
  },
  {
    region: 'Тверь облысы',
    city: 'Зубцов',
  },
  {
    region: 'Тверь облысы',
    city: 'Изоплит',
  },
  {
    region: 'Тверь облысы',
    city: 'Калашниково',
  },
  {
    region: 'Тверь облысы',
    city: 'Калинин',
  },
  {
    region: 'Тверь облысы',
    city: 'Қалязин',
  },
  {
    region: 'Тверь облысы',
    city: 'Кашин',
  },
  {
    region: 'Тверь облысы',
    city: 'Кесова Гора',
  },
  {
    region: 'Тверь облысы',
    city: 'Кимри',
  },
  {
    region: 'Тверь облысы',
    city: 'Конаково',
  },
  {
    region: 'Тверь облысы',
    city: 'Красный төбе',
  },
  {
    region: 'Тверь облысы',
    city: 'Кувшиново',
  },
  {
    region: 'Тверь облысы',
    city: 'Лесной',
  },
  {
    region: 'Тверь облысы',
    city: 'Ликославль',
  },
  {
    region: 'Тверь облысы',
    city: 'Максатиха',
  },
  {
    region: 'Тверь облысы',
    city: 'Молоко',
  },
  {
    region: 'Тверь облысы',
    city: 'Нелидово',
  },
  {
    region: 'Тверь облысы',
    city: 'Оленино',
  },
  {
    region: 'Тверь облысы',
    city: 'Осташков',
  },
  {
    region: 'Тверь облысы',
    city: 'Пено',
  },
  {
    region: 'Тверь облысы',
    city: 'Рамешки',
  },
  {
    region: 'Тверь облысы',
    city: 'Ржев',
  },
  {
    region: 'Тверь облысы',
    city: 'Сандово',
  },
  {
    region: 'Тверь облысы',
    city: 'Селизарово',
  },
  {
    region: 'Тверь облысы',
    city: 'Сонково',
  },
  {
    region: 'Тверь облысы',
    city: 'Спирово',
  },
  {
    region: 'Тверь облысы',
    city: 'Старица',
  },
  {
    region: 'Тверь облысы',
    city: 'Тверь',
  },
  {
    region: 'Тверь облысы',
    city: 'Торжок',
  },
  {
    region: 'Тверь облысы',
    city: 'Торопец',
  },
  {
    region: 'Тверь облысы',
    city: 'Удомля',
  },
  {
    region: 'Тверь облысы',
    city: 'Фирово',
  },
  {
    region: 'Томск облысы',
    city: 'Александровское',
  },
  {
    region: 'Томск облысы',
    city: 'Ашино',
  },
  {
    region: 'Томск облысы',
    city: 'Бақшар',
  },
  {
    region: 'Томск облысы',
    city: 'Батурино',
  },
  {
    region: 'Томск облысы',
    city: 'Белый Яр',
  },
  {
    region: 'Томск облысы',
    city: 'Зырян',
  },
  {
    region: 'Томск облысы',
    city: 'Итатка',
  },
  {
    region: 'Томск облысы',
    city: 'Қарғаш',
  },
  {
    region: 'Томск облысы',
    city: 'Катайга',
  },
  {
    region: 'Томск облысы',
    city: 'Кожевниково',
  },
  {
    region: 'Томск облысы',
    city: 'Колпашево',
  },
  {
    region: 'Томск облысы',
    city: 'Кривошеино',
  },
  {
    region: 'Томск облысы',
    city: 'Мельниково',
  },
  {
    region: 'Томск облысы',
    city: 'Молчаново',
  },
  {
    region: 'Томск облысы',
    city: 'Парабель',
  },
  {
    region: 'Томск облысы',
    city: 'Первомайское',
  },
  {
    region: 'Томск облысы',
    city: 'Подгорное',
  },
  {
    region: 'Томск облысы',
    city: 'Северск',
  },
  {
    region: 'Томск облысы',
    city: 'Стрежевой',
  },
  {
    region: 'Томск облысы',
    city: 'Томск',
  },
  {
    region: 'Томск облысы',
    city: 'Тымск',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Ақ-Овурак',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Бай Хаак',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Қызыл',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Самағалтай',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Сарығаш-Сеп',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Эстенс-Хол',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Тора-Хем',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Тұран',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Тэли',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Хову-Ақшы',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Чадан',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Шагонар',
  },
  {
    region: 'Тува (Тува Республикасы)',
    city: 'Ерзин',
  },
  {
    region: 'Тула облысы',
    city: 'Агеево',
  },
  {
    region: 'Тула облысы',
    city: 'Алексин',
  },
  {
    region: 'Тула облысы',
    city: 'Арсеньево',
  },
  {
    region: 'Тула облысы',
    city: 'Баджерс',
  },
  {
    region: 'Тула облысы',
    city: 'Бегичевский',
  },
  {
    region: 'Тула облысы',
    city: 'Белев',
  },
  {
    region: 'Тула облысы',
    city: 'Богородицк',
  },
  {
    region: 'Тула облысы',
    city: 'Болохово',
  },
  {
    region: 'Тула облысы',
    city: 'Велегож',
  },
  {
    region: 'Тула облысы',
    city: 'Венев',
  },
  {
    region: 'Тула облысы',
    city: 'Волово',
  },
  {
    region: 'Тула облысы',
    city: 'Жанарғылар',
  },
  {
    region: 'Тула облысы',
    city: 'Донское',
  },
  {
    region: 'Тула облысы',
    city: 'Дубна',
  },
  {
    region: 'Тула облысы',
    city: 'Эпифит',
  },
  {
    region: 'Тула облысы',
    city: 'Ефремов',
  },
  {
    region: 'Тула облысы',
    city: 'Заокский',
  },
  {
    region: 'Тула облысы',
    city: 'Казановка',
  },
  {
    region: 'Тула облысы',
    city: 'Кимовск',
  },
  {
    region: 'Тула облысы',
    city: 'Киреевск',
  },
  {
    region: 'Тула облысы',
    city: 'Куркино',
  },
  {
    region: 'Тула облысы',
    city: 'Ленинский',
  },
  {
    region: 'Тула облысы',
    city: 'Новомосковск',
  },
  {
    region: 'Тула облысы',
    city: 'Одоев',
  },
  {
    region: 'Тула облысы',
    city: 'Плавск',
  },
  {
    region: 'Тула облысы',
    city: 'Суворов',
  },
  {
    region: 'Тула облысы',
    city: 'Тула',
  },
  {
    region: 'Тула облысы',
    city: 'Торап',
  },
  {
    region: 'Тула облысы',
    city: 'Щекино',
  },
  {
    region: 'Тула облысы',
    city: 'Ясногорск',
  },
  {
    region: 'Түмен облысы',
    city: 'Абацкий',
  },
  {
    region: 'Түмен облысы',
    city: 'Аксарка',
  },
  {
    region: 'Түмен облысы',
    city: 'Армизонское',
  },
  {
    region: 'Түмен облысы',
    city: 'Аромашево',
  },
  {
    region: 'Түмен облысы',
    city: 'Белоярский',
  },
  {
    region: 'Түмен облысы',
    city: 'Бердюжье',
  },
  {
    region: 'Түмен облысы',
    city: 'Большой Сорокино',
  },
  {
    region: 'Түмен облысы',
    city: 'Вагай',
  },
  {
    region: 'Түмен облысы',
    city: 'Викулово',
  },
  {
    region: 'Түмен облысы',
    city: 'Винцили',
  },
  {
    region: 'Түмен облысы',
    city: 'Голышманово',
  },
  {
    region: 'Түмен облысы',
    city: 'Губкинский',
  },
  {
    region: 'Түмен облысы',
    city: 'Заводопетровский',
  },
  {
    region: 'Түмен облысы',
    city: 'Заводуковск',
  },
  {
    region: 'Түмен облысы',
    city: 'Излучинск',
  },
  {
    region: 'Түмен облысы',
    city: 'Исецкой',
  },
  {
    region: 'Түмен облысы',
    city: 'Есіл',
  },
  {
    region: 'Түмен облысы',
    city: 'Қазанское',
  },
  {
    region: 'Түмен облысы',
    city: 'Қазым-Мүс',
  },
  {
    region: 'Түмен облысы',
    city: 'Қоғалым',
  },
  {
    region: 'Түмен облысы',
    city: 'Кондинское',
  },
  {
    region: 'Түмен облысы',
    city: 'Красноселькуп',
  },
  {
    region: 'Түмен облысы',
    city: 'Лабитнанги',
  },
  {
    region: 'Түмен облысы',
    city: 'Ларяк',
  },
  {
    region: 'Түмен облысы',
    city: 'Мегион',
  },
  {
    region: 'Түмен облысы',
    city: 'Ерлер',
  },
  {
    region: 'Түмен облысы',
    city: 'Муравленко',
  },
  {
    region: 'Түмен облысы',
    city: 'Надым',
  },
  {
    region: 'Түмен облысы',
    city: 'Находка',
  },
  {
    region: 'Түмен облысы',
    city: 'Нефтеюганск',
  },
  {
    region: 'Түмен облысы',
    city: 'Нижневартовск',
  },
  {
    region: 'Түмен облысы',
    city: 'Нижняя Тавда',
  },
  {
    region: 'Түмен облысы',
    city: 'Новый Уренгой',
  },
  {
    region: 'Түмен облысы',
    city: 'Ноябрск',
  },
  {
    region: 'Түмен облысы',
    city: 'Няган',
  },
  {
    region: 'Түмен облысы',
    city: 'Октябрское',
  },
  {
    region: 'Түмен облысы',
    city: 'Омутинский',
  },
  {
    region: 'Түмен облысы',
    city: 'Радуга',
  },
  {
    region: 'Түмен облысы',
    city: 'Салехард',
  },
  {
    region: 'Түмен облысы',
    city: 'Сладково',
  },
  {
    region: 'Түмен облысы',
    city: 'Советский',
  },
  {
    region: 'Түмен облысы',
    city: 'Сургют',
  },
  {
    region: 'Түмен облысы',
    city: 'Тазовский',
  },
  {
    region: 'Түмен облысы',
    city: 'Тарко-Сале',
  },
  {
    region: 'Түмен облысы',
    city: 'Тобыл',
  },
  {
    region: 'Түмен облысы',
    city: 'Түмен',
  },
  {
    region: 'Түмен облысы',
    city: 'Уват',
  },
  {
    region: 'Түмен облысы',
    city: 'Ұңғыған',
  },
  {
    region: 'Түмен облысы',
    city: 'Упорово',
  },
  {
    region: 'Түмен облысы',
    city: 'Ұрай',
  },
  {
    region: 'Түмен облысы',
    city: 'Ханты-Мансийск',
  },
  {
    region: 'Түмен облысы',
    city: 'Юрийбей',
  },
  {
    region: 'Түмен облысы',
    city: 'Ялуторовск',
  },
  {
    region: 'Түмен облысы',
    city: 'Яр-Сале',
  },
  {
    region: 'Түмен облысы',
    city: 'Ярково',
  },
  {
    region: 'Удмуртия',
    city: 'Альнаши',
  },
  {
    region: 'Удмуртия',
    city: 'Балезино',
  },
  {
    region: 'Удмуртия',
    city: 'Вавож',
  },
  {
    region: 'Удмуртия',
    city: 'Воткинск',
  },
  {
    region: 'Удмуртия',
    city: 'Глазов',
  },
  {
    region: 'Удмуртия',
    city: 'Грахово',
  },
  {
    region: 'Удмуртия',
    city: 'Дебес',
  },
  {
    region: 'Удмуртия',
    city: 'Завялово',
  },
  {
    region: 'Удмуртия',
    city: 'Ойын',
  },
  {
    region: 'Удмуртия',
    city: 'Ижевск',
  },
  {
    region: 'Удмуртия',
    city: 'Кама',
  },
  {
    region: 'Удмуртия',
    city: 'Қамбарка',
  },
  {
    region: 'Удмуртия',
    city: 'Қарақұлино',
  },
  {
    region: 'Удмуртия',
    city: 'Кез',
  },
  {
    region: 'Удмуртия',
    city: 'Kizner',
  },
  {
    region: 'Удмуртия',
    city: 'Қиясово',
  },
  {
    region: 'Удмуртия',
    city: 'Красногорское',
  },
  {
    region: 'Удмуртия',
    city: 'Можга',
  },
  {
    region: 'Удмуртия',
    city: 'Сарапул',
  },
  {
    region: 'Удмуртия',
    city: 'Цельцы',
  },
  {
    region: 'Удмуртия',
    city: 'Сюмси',
  },
  {
    region: 'Удмуртия',
    city: 'Ува',
  },
  {
    region: 'Удмуртия',
    city: 'Устинов',
  },
  {
    region: 'Удмуртия',
    city: 'Жарқан',
  },
  {
    region: 'Удмуртия',
    city: 'Юкаменское',
  },
  {
    region: 'Удмуртия',
    city: 'Якшур-Бодия',
  },
  {
    region: 'Удмуртия',
    city: 'Яр',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Базар сызған',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Барыш',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Үлкен Нагаткино',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Вешкайма',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Жұту',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Димитровград',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Игнатовка',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Измайлово',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Инза',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Ишевка',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Канадей',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Қарсун',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Кузоватово',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Майна',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Жаңа Малыкла',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Новоспасское',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Павловка',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Радищево',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Сенгилий',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Старая Кулатка',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Ескі тұйық көше',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Сурское',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Теренга',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Ульяновск',
  },
  {
    region: 'Ульяновск облысы',
    city: 'Аттика',
  },
  {
    region: 'Орал облысы',
    city: 'Ақсай',
  },
  {
    region: 'Орал облысы',
    city: 'Даринский',
  },
  {
    region: 'Орал облысы',
    city: 'Деркөл',
  },
  {
    region: 'Орал облысы',
    city: 'Жамбейіт',
  },
  {
    region: 'Орал облысы',
    city: 'Жаныбек',
  },
  {
    region: 'Орал облысы',
    city: 'Казталовка',
  },
  {
    region: 'Орал облысы',
    city: 'Қалмақов',
  },
  {
    region: 'Орал облысы',
    city: 'Қаратөбе',
  },
  {
    region: 'Орал облысы',
    city: 'Переметное',
  },
  {
    region: 'Орал облысы',
    city: 'Сайхин',
  },
  {
    region: 'Орал облысы',
    city: 'Орал',
  },
  {
    region: 'Орал облысы',
    city: 'Федоровка',
  },
  {
    region: 'Орал облысы',
    city: 'Фурманово',
  },
  {
    region: 'Орал облысы',
    city: 'Чапаев',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Амурск',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Аян',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Қайың',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Бикін',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Бира',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Бірақан',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Богородское',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Болонья',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Ванино',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Волочаевка Вторая',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Үстірт',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Вяземский',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Тау',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Гурское',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Дормидонтовка',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Ильичтің өсиеттері',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Әк',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Иннокентьевка',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Комсомольск-он-Амур',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Ленинское',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Нелькан',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Николаевск-он-Амур',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Облючье',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Охотск',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Переяславка',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Смидович',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Совецкая Гаван',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Софиск',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Троицкое',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Түгір',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Хабаровск',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Чегдомы',
  },
  {
    region: 'Хабаровск аумағы',
    city: 'Чумикан',
  },
  {
    region: 'Хакасия',
    city: 'Абақан',
  },
  {
    region: 'Хакасия',
    city: 'Саяногорск',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Аган',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Игрим',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Излучинск',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Лангепас',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Лянтор',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Мегион',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Нефтеюганск',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Нижневартовск',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Няган',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Покачи',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Приобие',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Пыт-Ях',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Радуга',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Сургют',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Ұрай',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Ханты-Мансийск',
  },
  {
    region: 'Ханты-Мансийск автономиялық округі',
    city: 'Югорск',
  },
  {
    region: 'Челябі облысы',
    city: 'Агаповка',
  },
  {
    region: 'Челябі облысы',
    city: 'Арғаяш',
  },
  {
    region: 'Челябі облысы',
    city: 'Аша',
  },
  {
    region: 'Челябі облысы',
    city: 'Бақал',
  },
  {
    region: 'Челябі облысы',
    city: 'Бреда',
  },
  {
    region: 'Челябі облысы',
    city: 'Варна',
  },
  {
    region: 'Челябі облысы',
    city: 'Верхнеуральск',
  },
  {
    region: 'Челябі облысы',
    city: 'Верхный Уфали',
  },
  {
    region: 'Челябі облысы',
    city: 'Эманжелинск',
  },
  {
    region: 'Челябі облысы',
    city: 'Златуст',
  },
  {
    region: 'Челябі облысы',
    city: 'Қарабаш',
  },
  {
    region: 'Челябі облысы',
    city: 'Қарталы',
  },
  {
    region: 'Челябі облысы',
    city: 'Бекініс',
  },
  {
    region: 'Челябі облысы',
    city: 'Катав-Ивановск',
  },
  {
    region: 'Челябі облысы',
    city: 'Көпейск',
  },
  {
    region: 'Челябі облысы',
    city: 'Коркино',
  },
  {
    region: 'Челябі облысы',
    city: 'Күнашак',
  },
  {
    region: 'Челябі облысы',
    city: 'Құса',
  },
  {
    region: 'Челябі облысы',
    city: 'Кыштым',
  },
  {
    region: 'Челябі облысы',
    city: 'Магнитогорск',
  },
  {
    region: 'Челябі облысы',
    city: 'Миасс',
  },
  {
    region: 'Челябі облысы',
    city: 'Озерск',
  },
  {
    region: 'Челябі облысы',
    city: 'Октябрское',
  },
  {
    region: 'Челябі облысы',
    city: 'Пласт',
  },
  {
    region: 'Челябі облысы',
    city: 'Сатка',
  },
  {
    region: 'Челябі облысы',
    city: 'Сим',
  },
  {
    region: 'Челябі облысы',
    city: 'Снежинск',
  },
  {
    region: 'Челябі облысы',
    city: 'Трехгорный',
  },
  {
    region: 'Челябі облысы',
    city: 'Троицк',
  },
  {
    region: 'Челябі облысы',
    city: 'Увельский',
  },
  {
    region: 'Челябі облысы',
    city: 'Үй',
  },
  {
    region: 'Челябі облысы',
    city: 'Уст-Катав',
  },
  {
    region: 'Челябі облысы',
    city: 'Ferchampenoise',
  },
  {
    region: 'Челябі облысы',
    city: 'Чебаркуль',
  },
  {
    region: 'Челябі облысы',
    city: 'Челябинск',
  },
  {
    region: 'Челябі облысы',
    city: 'Чесма',
  },
  {
    region: 'Челябі облысы',
    city: 'Южно-Орал',
  },
  {
    region: 'Челябі облысы',
    city: 'Юрюзан',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Аргун',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Грозный',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Гудермес',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Малгобек',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Назран',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Наурыз',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Ножай-Киіз үй',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Орджоникидзе',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Советский',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Урюс-Мартан',
  },
  {
    region: 'Шешен-Ингушетия',
    city: 'Шапан',
  },
  {
    region: 'Чита облысы',
    city: 'Агинское',
  },
  {
    region: 'Чита облысы',
    city: 'Ақсенов-Зилов',
  },
  {
    region: 'Чита облысы',
    city: 'Ақша',
  },
  {
    region: 'Чита облысы',
    city: 'Александровск завод',
  },
  {
    region: 'Чита облысы',
    city: 'Амазар',
  },
  {
    region: 'Чита облысы',
    city: 'Арбағар',
  },
  {
    region: 'Чита облысы',
    city: 'Атамановка',
  },
  {
    region: 'Чита облысы',
    city: 'Бали',
  },
  {
    region: 'Чита облысы',
    city: 'Тазы',
  },
  {
    region: 'Чита облысы',
    city: 'Букачач',
  },
  {
    region: 'Чита облысы',
    city: 'Газимурский завод',
  },
  {
    region: 'Чита облысы',
    city: 'Давенда',
  },
  {
    region: 'Чита облысы',
    city: 'Дарасун',
  },
  {
    region: 'Чита облысы',
    city: 'Ағаш',
  },
  {
    region: 'Чита облысы',
    city: 'Дулдурга',
  },
  {
    region: 'Чита облысы',
    city: 'Джиндо',
  },
  {
    region: 'Чита облысы',
    city: 'Забайкалск',
  },
  {
    region: 'Чита облысы',
    city: 'Итхака',
  },
  {
    region: 'Чита облысы',
    city: 'Қалға',
  },
  {
    region: 'Чита облысы',
    city: 'Қарымское',
  },
  {
    region: 'Чита облысы',
    city: 'Лақап аты',
  },
  {
    region: 'Чита облысы',
    city: 'Ключевский',
  },
  {
    region: 'Чита облысы',
    city: 'Көкой',
  },
  {
    region: 'Чита облысы',
    city: 'Краснокаменск',
  },
  {
    region: 'Чита облысы',
    city: 'Красный Чикой',
  },
  {
    region: 'Чита облысы',
    city: 'Қыра',
  },
  {
    region: 'Чита облысы',
    city: 'Моготуи',
  },
  {
    region: 'Чита облысы',
    city: 'Могоча',
  },
  {
    region: 'Чита облысы',
    city: 'Нерчинск',
  },
  {
    region: 'Чита облысы',
    city: 'Нерчинск зауыты',
  },
  {
    region: 'Чита облысы',
    city: 'Нижний Часучей',
  },
  {
    region: 'Чита облысы',
    city: 'Қалайы',
  },
  {
    region: 'Чита облысы',
    city: 'Первомайский',
  },
  {
    region: 'Чита облысы',
    city: 'Петровск-Забайкалский',
  },
  {
    region: 'Чита облысы',
    city: 'Приаргунск',
  },
  {
    region: 'Чита облысы',
    city: 'Сретенск',
  },
  {
    region: 'Чита облысы',
    city: 'Өлі аяғы',
  },
  {
    region: 'Чита облысы',
    city: 'Ұшып бара жатыр',
  },
  {
    region: 'Чита облысы',
    city: 'Хилок',
  },
  {
    region: 'Чита облысы',
    city: 'Шара',
  },
  {
    region: 'Чита облысы',
    city: 'Чернышевск',
  },
  {
    region: 'Чита облысы',
    city: 'Чита',
  },
  {
    region: 'Чита облысы',
    city: 'Шелопугино',
  },
  {
    region: 'Чита облысы',
    city: 'Шилка',
  },
  {
    region: 'Чувашия',
    city: 'Алатыр',
  },
  {
    region: 'Чувашия',
    city: 'Аликово',
  },
  {
    region: 'Чувашия',
    city: 'Батырева',
  },
  {
    region: 'Чувашия',
    city: 'Буйнск',
  },
  {
    region: 'Чувашия',
    city: 'Вурнарий',
  },
  {
    region: 'Чувашия',
    city: 'Ибреси',
  },
  {
    region: 'Чувашия',
    city: 'Қанаш',
  },
  {
    region: 'Чувашия',
    city: 'Қира',
  },
  {
    region: 'Чувашия',
    city: 'Комсомольское',
  },
  {
    region: 'Чувашия',
    city: 'Красноармейское',
  },
  {
    region: 'Чувашия',
    city: 'Красный Четай',
  },
  {
    region: 'Чувашия',
    city: 'Кугеси',
  },
  {
    region: 'Чувашия',
    city: 'Мариинский посад',
  },
  {
    region: 'Чувашия',
    city: 'Моргауши',
  },
  {
    region: 'Чувашия',
    city: 'Новочебоксарск',
  },
  {
    region: 'Чувашия',
    city: 'Поретское',
  },
  {
    region: 'Чувашия',
    city: 'Үрмарс',
  },
  {
    region: 'Чувашия',
    city: 'Цивилск',
  },
  {
    region: 'Чувашия',
    city: 'Чебоксары',
  },
  {
    region: 'Чувашия',
    city: 'Шемурша',
  },
  {
    region: 'Чувашия',
    city: 'Сумерля',
  },
  {
    region: 'Чувашия',
    city: 'Ядрин',
  },
  {
    region: 'Чувашия',
    city: 'Жалчики',
  },
  {
    region: 'Чувашия',
    city: 'Янтыково',
  },
  {
    region: 'Чукотка автономиялық ауданы',
    city: 'Анадыр',
  },
  {
    region: 'Чукотка автономиялық ауданы',
    city: 'Билибино',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Губкинский',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Заполярный',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Муравленко',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Надым',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Новый Уренгой',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Ноябрск',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Пуровск',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Салехард',
  },
  {
    region: 'Ямало-Ненец автономиялық округі',
    city: 'Тарко',
  },
  {
    region: 'Ярославль облысы',
    city: 'Андропов',
  },
  {
    region: 'Ярославль облысы',
    city: 'Берендеево',
  },
  {
    region: 'Ярославль облысы',
    city: 'Үлкен ауыл',
  },
  {
    region: 'Ярославль облысы',
    city: 'Борисоглебский',
  },
  {
    region: 'Ярославль облысы',
    city: 'Брейтово',
  },
  {
    region: 'Ярославль облысы',
    city: 'Бурмакино',
  },
  {
    region: 'Ярославль облысы',
    city: 'Варегово',
  },
  {
    region: 'Ярославль облысы',
    city: 'Волга',
  },
  {
    region: 'Ярославль облысы',
    city: 'Гаврилов Ям',
  },
  {
    region: 'Ярославль облысы',
    city: 'Данилов',
  },
  {
    region: 'Ярославль облысы',
    city: 'Махаббат',
  },
  {
    region: 'Ярославль облысы',
    city: 'Мышкино',
  },
  {
    region: 'Ярославль облысы',
    city: 'Некрасовское',
  },
  {
    region: 'Ярославль облысы',
    city: 'Жаңа некус',
  },
  {
    region: 'Ярославль облысы',
    city: 'Переславль-Залесский',
  },
  {
    region: 'Ярославль облысы',
    city: 'Пошехонье-Володарск',
  },
  {
    region: 'Ярославль облысы',
    city: 'Ростов',
  },
  {
    region: 'Ярославль облысы',
    city: 'Рыбинск',
  },
  {
    region: 'Ярославль облысы',
    city: 'Тутаев',
  },
  {
    region: 'Ярославль облысы',
    city: 'Углич',
  },
  {
    region: 'Ярославль облысы',
    city: 'Ярославль',
  },
]
export default RegionCityListKz
